<template>
  <div class="developer">
    <div class="center" v-if="name == 'developer'">
      <div class="area">
        <div class="title">
          <span>
            <a href="/developer/platform">平台介绍</a>
          </span>
          <Icon type="md-arrow-dropright" color="#D8D8D8" style="margin-bottom: 4px" />
        </div>
        <Row class="links" type="flex">
          <Col
            :lg="3"
            :xs="10"
            class="platform-block"
            v-for="(item, index) in platformItems"
            :key="index"
          >
            <div
              @click="gotoPlatform($event, item)"
              class="inner-block"
              @mouseover="onBlockMouseOver($event, item)"
              @mouseleave="onBlockMouseLeave($event, item)"
            >
              <img class="icon" :src="item.icon" :alt="item.name" />
              <span style="padding-top: 23px; margin-left: 14px; font-size: 19px">{{item.name}}</span>
            </div>
          </Col>
        </Row>
      </div>

      <div class="area">
        <div class="title">
          <span>
            <a href="/developer/document?production=audio">产品文档</a>
          </span>
          <Icon type="md-arrow-dropright" color="#D8D8D8" style="margin-bottom: 4px" />
        </div>
        <Row class="links" type="flex">
          <Col :lg="3" :xs="10" class="product-block" v-for="(item, index) in doc" :key="index">
            <div
              class="inner-block"
              @mouseover="onBlockMouseOver($event, item)"
              @mouseleave="onBlockMouseLeave($event, item)"
              @click="gotoDocDetail($event, item)"
            >
              <img class="product-icon" :src="item.icon" :alt="item.name" />
              <div style="display: block; padding-top: 23px; ">
                <span style="margin-left: 14px;">{{item.title}}</span>
                <div
                  class="item-desc"
                  :style="{color: item.active ? '#A2AEFF' : '#9A9A9F'}"
                >{{item.desc}}</div>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div class="area">
        <div class="title">
          <span>
            <a href="/developer/sdk">SDK</a>
          </span>
          <Icon type="md-arrow-dropright" color="#D8D8D8" style="margin-bottom: 4px" />
        </div>
        <Row class="links" type="flex">
          <Col :lg="3" :xs="10" class="sdk-block" v-for="(item, index) in sdk" :key="index">
            <div
              class="inner-block"
              @mouseover="onBlockMouseOver($event, item)"
              @mouseleave="onBlockMouseLeave($event, item)"
            >
              <img class="sdk-icon" :src="item.icon" :alt="item.name" />
              <div style="display: block; padding-top: 22px; ">
                <div style="margin-left:13px">{{item.title}}</div>
                <div
                  class="item-desc"
                  :style="{color: item.active ? '#A2AEFF' : '#9A9A9F'}"
                >{{item.desc}}</div>
                <div style="font-size:16px;margin-left:13px; margin-top:6px">
                  <span
                    class="item-oper"
                    @click="downloadSdk(index)"
                    :style="{color: item.active ? 'white' : '#9A9A9F'}"
                  >立即下载</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div class="area">
        <div class="title">
          <span>
            <a href="/developer/demo">DEMO</a>
          </span>
          <Icon type="md-arrow-dropright" color="#D8D8D8" style="margin-bottom: 4px" />
        </div>
        <Row class="links" type="flex">
          <Col :lg="3" :xs="10" class="sdk-block" v-for="(item, index) in demo" :key="index">
            <div
              class="inner-block"
              @mouseover="onBlockMouseOver($event, item)"
              @mouseleave="onBlockMouseLeave($event, item)"
            >
              <img class="sdk-icon" :src="item.icon" :alt="item.name" />
              <div style="display: block; padding-top: 22px; ">
                <div style="margin-left:13px">{{item.title}}</div>
                <div
                  class="item-desc"
                  :style="{color: item.active ? '#A2AEFF' : '#9A9A9F'}"
                >{{item.desc}}</div>
                <div style="font-size:16px;margin-left:13px; margin-top:6px">
                  <span
                    class="item-oper"
                    @click="downloadDemo(index, item)"
                    :style="{color: item.active ? 'white' : '#9A9A9F'}"
                  >{{item.oper}}</span>
                  <img
                    v-if="item.qrCode"
                    style="float: right"
                    :src="item.active ? require('../images/developer/icon_qrcode_focus.png') : require('../images/developer/icon_qrcode.png')"
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div class="area">
        <div class="title">
          其它
          <Icon type="md-arrow-dropright" color="#D8D8D8" style="margin-bottom: 4px" />
        </div>
        <Row class="links" type="flex">
          <Col :lg="3" :xs="10" class="sdk-block" v-for="(item, index) in other" :key="index">
            <div
              class="inner-block"
              @mouseover="onBlockMouseOver($event, item)"
              @mouseleave="onBlockMouseLeave($event, item)"
            >
              <img class="sdk-icon" :src="item.icon" :alt="item.name" />
              <div style="display: block; padding-top: 22px; ">
                <div style="font-size:20px;margin-left:13px">{{item.title}}</div>
                <div
                  class="item-desc"
                  :style="{color: item.active ? '#A2AEFF' : '#9A9A9F'}"
                >{{item.desc}}</div>
                <div style="font-size:16px;margin-left:13px; margin-top:6px">
                  <span
                    class="item-oper"
                    @click="downloadOther(index, item)"
                    :style="{color: item.active ? 'white' : '#9A9A9F'}"
                  >{{item.oper}}</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div style="height: 10px"></div>

      <!-- <div class="area">
        <router-link to="/developer/download">
          <div class="title">
            下载
            <Icon type="md-arrow-dropright" color="#D8D8D8" style="margin-bottom: 4px"/>
          </div>
        </router-link>
        <div class="sub-title">SDK下载</div>
        <Row class="links" type="flex">
          <Col :lg="3" :xs="10" class="block" v-for="(item, index) in sdk" :key="index">
            <div class="name">
              <img class="icon" :src="item.icon" :alt="item.name" />
              <router-link to="/developer/download">{{item.title}}</router-link>
            </div>
            <ul>
              <li>版本描述</li>
              <li>
                <a :href="item.downloadUrl" target="_blank">免费下载</a>
              </li>
            </ul>
          </Col>
        </Row>

        <div class="sub-title" style="margin-top: 0.4rem;">插件下载</div>
        <Row class="links" type="flex">
          <Col :lg="4" class="block" v-for="(item, index) in plugins" :key="index">
            <div class="name">
              <img class="icon" :src="item.icon" :alt="item.name" />
              <router-link to="/developer/download">{{item.title}}</router-link>
            </div>
            <ul>
              <li>版本描述</li>
              <li>
                <a :href="item.downloadUrl" target="_blank">免费下载</a>
              </li>
            </ul>
          </Col>
        </Row>
      </div>-->

      <!-- <div class="area">
        <router-link to="/developer/code">
          <div class="title">
            <Icon
              type="ios-arrow-forward"
              color="#D8D8D8"
            />
            代码
          </div>
        </router-link>
        <div class="sub-title">MeetingDemo</div>
        <Row class="links" type="flex">
          <Col :lg="3" :xs="10" class="block" v-for="(item, index) in code" :key="index">
            <div class="name">
              <img class="icon" :src="item.icon" :alt="item.name" />
              <router-link to="/developer/code">{{item.title}}</router-link>
            </div>
            <ul>
              <li>
                <a :href="item.url" target="_blank">源码参考</a>
              </li>
              <li @click="clickAcc(item)">
                <a :href="item.downloadUrl" target="_blank">Demo体验</a>
              </li>
            </ul>
          </Col>
        </Row>

        <div class="sub-title" style="margin-top: 0.4rem;">TokenGenerator</div>
        <Row class="links" type="flex">
          <Col :lg="3" :xs="10" class="block" v-for="(item, index) in token" :key="index">
            <div class="name">
              <img class="icon" :src="item.icon" :alt="item.name" />
              <router-link to="/developer/code">{{item.title}}</router-link>
              <a :href="item.url" target="_blank">{{item.title}}</a>
            </div>
            <ul>
            <li><a
                :href="item.url"
                target="_blank"
              >源码参考</a></li>
            </ul>
          </Col>
        </Row>
      </div>-->

      <Modal title="MeetingDemo" v-model="showModal" width="280rem" :closable="false">
        <img :src="modalImg" alt="modalImg" />
      </Modal>
    </div>

    <router-view />
  </div>
</template>

<script>
import { docUrl } from "../service/api.js";
export default {
  data() {
    return {
      platformItems: [
        {
          name: "平台概述",
          area: "platform_desc",
          active: false,
          normalIcon: require("../images/developer/overview.png"),
          focusIcon: require("../images/developer/overview_focus.png"),
          icon: require("../images/developer/overview.png")
        },
        {
          name: "基本概念",
          area: "basic_concept",
          active: false,
          normalIcon: require("../images/developer/concept.png"),
          focusIcon: require("../images/developer/concept_focus.png"),
          icon: require("../images/developer/concept.png")
        },
        {
          name: "体验试用",
          area: "experience_test",
          active: false,
          normalIcon: require("../images/developer/trial.png"),
          focusIcon: require("../images/developer/trial_focus.png"),
          icon: require("../images/developer/trial.png")
        },
        {
          name: "应用集成",
          area: "app_integrate",
          active: false,
          normalIcon: require("../images/developer/integration.png"),
          focusIcon: require("../images/developer/integration_focus.png"),
          icon: require("../images/developer/integration.png")
        },
        {
          name: "价格详情",
          area: "price",
          active: false,
          normalIcon: require("../images/developer/price.png"),
          focusIcon: require("../images/developer/price_focus.png"),
          icon: require("../images/developer/price.png")
        },
        {
          name: "错误码",
          area: "errno",
          active: false,
          normalIcon: require("../images/developer/error.png"),
          focusIcon: require("../images/developer/error_focus.png"),
          icon: require("../images/developer/error.png")
        }
      ],
      doc: [
        {
          active: false,
          normalIcon: require("../images/doc/audio.png"),
          focusIcon: require("../images/doc/audio_focus.png"),
          icon: require("../images/doc/audio.png"),
          title: "音频通信",
          production: "audio",
          desc: "多人语音通话"
        },
        {
          active: false,
          normalIcon: require("../images/doc/video.png"),
          focusIcon: require("../images/doc/video_focus.png"),
          icon: require("../images/doc/video.png"),
          title: "视频通信",
          production: "video",
          desc: "高清视频通话"
        },
        {
          active: false,
          normalIcon: require("../images/doc/shared.png"),
          focusIcon: require("../images/doc/shared_focus.png"),
          icon: require("../images/doc/shared.png"),
          title: "屏幕共享",
          production: "screen_share",
          desc: "分享桌面内容"
        },
        {
          active: false,
          normalIcon: require("../images/doc/channel.png"),
          focusIcon: require("../images/doc/channel_focus.png"),
          icon: require("../images/doc/channel.png"),
          title: "信令通道",
          production: "tmsg",
          desc: "发送自定义消息"
        },
        {
          active: false,
          normalIcon: require("../images/doc/call.png"),
          focusIcon: require("../images/doc/call_focus.png"),
          icon: require("../images/doc/call.png"),
          title: "在线",
          production: "online",
          desc: "呼叫在线用户"
        },
        {
          active: false,
          normalIcon: require("../images/developer/record.png"),
          focusIcon: require("../images/developer/record_focus.png"),
          icon: require("../images/doc/record.png"),
          title: "云录制",
          production: "record",
          desc: "服务器端录制"
        },
        {
          active: false,
          normalIcon: require("../images/developer/live.png"),
          focusIcon: require("../images/developer/live_focus.png"),
          icon: require("../images/doc/live.png"),
          title: "云直播",
          production: "live",
          desc: "服务器端直播"
        }
      ],
      sdk: [
        {
          title: "Windows",
          desc: "C++/32bit",
          active: false,
          normalIcon: require("../images/developer/windows.png"),
          focusIcon: require("../images/developer/windows_focus.png"),
          icon: require("../images/developer/windows.png")
        },
        {
          title: "Windows",
          desc: "C#/32bit",
          active: false,
          normalIcon: require("../images/developer/windows.png"),
          focusIcon: require("../images/developer/windows_focus.png"),
          icon: require("../images/developer/windows.png")
        },
        {
          title: "Android",
          desc: "Java/32bit",
          active: false,
          downloadUrl:
            "http://fs.hst.com/download/paas/sdk/android/fsp_sdk_android.zip",
          normalIcon: require("../images/developer/android.png"),
          focusIcon: require("../images/developer/android_focus.png"),
          icon: require("../images/developer/android.png")
        },
        {
          title: "iOS",
          desc: "ObjectiveC",
          active: false,
          downloadUrl:
            "http://fs.hst.com/download/paas/sdk/ios/fsp_sdk_ios.zip",
          normalIcon: require("../images/developer/ios.png"),
          focusIcon: require("../images/developer/ios_focus.png"),
          icon: require("../images/developer/ios.png")
        },
        {
          title: "macOS",
          desc: "ObjectiveC",
          active: false,
          downloadUrl:
            "http://fs.hst.com/download/paas/sdk/macos/fsp_sdk_mac.zip",
          normalIcon: require("../images/developer/macos.png"),
          focusIcon: require("../images/developer/macos_focus.png"),
          icon: require("../images/developer/macos.png")
        },
        {
          title: "Web",
          desc: "Javascript",
          active: false,
          downloadUrl:
            "http://fs.hst.com/download/paas/sdk/web/fsp_sdk_web.zip",
          normalIcon: require("../images/developer/web.png"),
          focusIcon: require("../images/developer/web_focus.png"),
          icon: require("../images/developer/web.png")
        },
        {
          title: "WeChat",
          desc: "Javascript",
          active: false,
          downloadUrl:
            "http://fs.hst.com/download/paas/sdk/wechat/fsp_sdk_wechat.zip",
          normalIcon: require("../images/developer/wechat.png"),
          focusIcon: require("../images/developer/wechat_focus.png"),
          icon: require("../images/developer/wechat.png")
        },
        {
          title: "Harmony",
          desc: "ArkTs",
          active: false,
          downloadUrl:
            "http://fs.hst.com/download/paas/sdk/harmony/fsp_sdk_harmony.zip",
          normalIcon: require("../images/developer/web.png"),
          focusIcon: require("../images/developer/web_focus.png"),
          icon: require("../images/developer/web.png")
        }
      ],
      demo: [
        {
          title: "MeetingDemo",
          desc: "Windows(C++)",
          active: false,
          qrCode: false,
          oper: "下载体验",
          normalIcon: require("../images/developer/meetingdemo.png"),
          focusIcon: require("../images/developer/meetingdemo_focus.png"),
          icon: require("../images/developer/meetingdemo.png"),
          accUrl:
            "http://fs.hst.com/download/paas/meetingdemo/windows/meetingdemo_windows.zip"
        },
        {
          title: "MeetingDemo",
          desc: "Windows(C#)",
          active: false,
          qrCode: false,
          oper: "下载体验",
          normalIcon: require("../images/developer/meetingdemo.png"),
          focusIcon: require("../images/developer/meetingdemo_focus.png"),
          icon: require("../images/developer/meetingdemo.png"),
          accUrl:
            "http://fs.hst.com/download/paas/meetingdemo/windows/meetingdemo_windows.zip"
        },
        {
          title: "MeetingDemo",
          desc: "Android",
          active: false,
          qrCode: true,
          oper: "扫码体验",
          normalIcon: require("../images/developer/meetingdemo.png"),
          focusIcon: require("../images/developer/meetingdemo_focus.png"),
          icon: require("../images/developer/meetingdemo.png"),
          accImg: require("../images/code/meetingdemo_android.png")
        },
        {
          title: "MeetingDemo",
          desc: "iOS",
          active: false,
          qrCode: true,
          oper: "扫码体验",
          normalIcon: require("../images/developer/meetingdemo.png"),
          focusIcon: require("../images/developer/meetingdemo_focus.png"),
          icon: require("../images/developer/meetingdemo.png"),
          accImg: require("../images/code/meetingdemo_ios.png")
        },
        {
          title: "MeetingDemo",
          desc: "macOS",
          active: false,
          qrCode: false,
          oper: "下载体验",
          normalIcon: require("../images/developer/meetingdemo.png"),
          focusIcon: require("../images/developer/meetingdemo_focus.png"),
          icon: require("../images/developer/meetingdemo.png"),
          accUrl:
            "http://fs.hst.com/download/paas/meetingdemo/mac/meetingdemo_mac.zip"
        },
        {
          title: "MeetingDemo",
          desc: "Web",
          active: false,
          qrCode: false,
          oper: "在线体验",
          normalIcon: require("../images/developer/meetingdemo.png"),
          focusIcon: require("../images/developer/meetingdemo_focus.png"),
          icon: require("../images/developer/meetingdemo.png"),
          accUrl: "https://fspweb.hst.com"
        },
        {
          title: "MeetingDemo",
          desc: "WeChat",
          active: false,
          qrCode: true,
          oper: "扫码体验",
          normalIcon: require("../images/developer/meetingdemo.png"),
          focusIcon: require("../images/developer/meetingdemo_focus.png"),
          icon: require("../images/developer/meetingdemo.png"),
          accImg: require("../images/code/meetingdemo_wx.jpg")
        },
           {
          title: "MeetingDemo",
          desc: "Harmony(ArkTs)",
          active: false,
          qrCode: false,
          oper: "下载体验",
          normalIcon: require("../images/developer/meetingdemo.png"),
          focusIcon: require("../images/developer/meetingdemo_focus.png"),
          icon: require("../images/developer/meetingdemo.png"),
          accUrl: "http://fs.hst.com/download/paas/meetingdemo/mac/meetingdemo_mac.zip"
            // "http://fs.hst.com/download/paas/meetingdemo/harmony/meetingdemo_harmony.zip"
        },
        {
          title: "RecordDemo",
          desc: "Web",
          active: false,
          qrCode: false,
          oper: "在线体验",
          normalIcon: require("../images/developer/record.png"),
          focusIcon: require("../images/developer/record_focus.png"),
          icon: require("../images/developer/record.png"),
          accImg: require("../images/code/meetingdemo_wx.jpg")
        },
        {
          title: "LiveDemo",
          desc: "web",
          active: false,
          qrCode: false,
          oper: "在线体验",
          normalIcon: require("../images/developer/live.png"),
          focusIcon: require("../images/developer/live_focus.png"),
          icon: require("../images/developer/live.png"),
          accImg: require("../images/code/meetingdemo_wx.jpg")
        }
      ],
      other: [
        {
          title: "RTSP Plugin",
          desc: "Windows",
          active: false,
          oper: "立即下载",
          normalIcon: require("../images/developer/plugin.png"),
          focusIcon: require("../images/developer/plugin_focus.png"),
          icon: require("../images/developer/plugin.png")
        },
        {
          title: "TokenGen",
          desc: "C++源码",
          active: false,
          oper: "获取源码",
          normalIcon: require("../images/developer/c++.png"),
          focusIcon: require("../images/developer/c++_focus.png"),
          icon: require("../images/developer/c++.png")
        },
        {
          title: "TokenGen",
          desc: "Java源码",
          active: false,
          oper: "获取源码",
          normalIcon: require("../images/developer/java.png"),
          focusIcon: require("../images/developer/java_focus.png"),
          icon: require("../images/developer/java.png")
        }
      ],
      showModal: false,
      modalImg: "",
      sdkInfoList: [],
      demoInfoList: [],
      tokenGenCpp: null,
      tokenGenJava: null,
      rtspPlugin: null
    };
  },

  computed: {
    name() {
      return this.$route.name;
    }
  },

  mounted() {
    fetch(docUrl + "config/sdk.json", {method: "GET", mode: "cors"}).then(res => {
        return res.json();
    }).then(data => {
      this.sdkInfoList = data.jsonData[0].data;
      this.rtspPlugin = data.jsonData[1].data[0];
    });

    fetch(docUrl + "config/demo.json", {method: "GET", mode: "cors"}).then(res => {
        return res.json();
    }).then(data => {
      this.demoInfoList = data.jsonData[0].data;
      this.demoInfoList = this.demoInfoList.concat(data.jsonData[1].data);
    });

    fetch(docUrl + "config/code.json", {method: "GET", mode: "cors"}).then(res => {
        return res.json();
    }).then(data => {
      this.tokenGenCpp = data.jsonData[0].data[0];
      this.tokenGenJava = data.jsonData[0].data[1];
    });
  },

  methods: {
    downloadSdk(index) {
      // 这里要求sdkInfoList和sdk的内容顺序一一对应
      window.open(this.sdkInfoList[index].downloadUrl);
    },
    downloadDemo(index, item) {
      if (item.oper == "扫码体验") {
        this.showModal = true;
        this.modalImg = this.demoInfoList[index].accImg;
      } else if (item.oper == "下载体验") {
        console.error(this.demoInfoList)
        window.open(this.demoInfoList[index].accUrl);
      } else if (item.oper == "在线体验") {
        window.open(this.demoInfoList[index].accUrl, "_blank");
      }
    },
    downloadOther(index, item) {
      if (item.oper == "立即下载") {
        window.open(this.rtspPlugin.downloadUrl);
      } else {
        if (item.desc == "C++源码") {
          window.open(this.tokenGenCpp.url);
        } else {
          window.open(this.tokenGenJava.url);
        }
      }
    },
    onBlockMouseOver($event, item) {
      $event.currentTarget.className = "inner-block block-active";
      item.icon = item.focusIcon;
      item.active = true;
    },
    onBlockMouseLeave($event, item) {
      $event.currentTarget.className = "inner-block";
      item.icon = item.normalIcon;
      item.active = false;
    },
    gotoPlatform($event, item) {
      // 跳转前恢复CSS设置，防止回退CSS残留
      this.onBlockMouseLeave($event, item);
      this.$router.push({
        name: "platform",
        params: { area: item.area }
      });
    },

    gotoDocDetail($event, item) {
      // 跳转前恢复CSS设置，防止回退CSS残留
      this.onBlockMouseLeave($event, item);
      this.$router.push({
        name: "document",
        query: { production: item.production },
        params: {
          title: item.title,
          area: "product_summary"
        }
      });
    },

    clickAcc(item) {
      if (item.accUrl) {
        window.open(item.accUrl);
      } else if (item.accImg) {
        this.showModal = true;
        this.modalImg = item.accImg;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.developer {
  background: #f0f2f5;
  height: 100%;
}

.center {
  max-width: 13.66rem;
  margin: 0 auto;
  padding-top: 0.2rem;
  text-align: left;
  min-height: 93vh;
  // background: #ffffff;

  .ivu-icon.ivu-icon-ios-arrow-forward {
    font-size: 0.35rem;
  }

  .area {
    background: #ffffff;
    margin-bottom: 0.2rem;
    border-radius: 2px;
    font-family: "NotoSansHans-Regular";
  }

  .area:hover {
    color: rgba(73, 94, 235, 1);
  }

  .title {
    font-size: 21px;
    font-family: "NotoSansHans-Regular";
    font-weight: 500;
    color: #242425;

    padding-top: 0.31rem;
    padding-bottom: 0.2rem;
    margin-left: 40px;
  }

  .sub-title {
    font-size: 0.2rem;
    font-family: "NotoSansHans-Regular";
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 0.01rem solid #dadada;
    padding-bottom: 0.19rem;
    margin-left: 0.45rem;
    margin-bottom: 0.2rem;
    margin-right: 0.5rem;
  }

  .platform-block {
    border: 1px solid #f6f7f9;
    height: 64px;
    width: 203px;
    margin-right: 15px;
    border-radius: 4px;
    margin-bottom: 30px;
    transition: all 0.3s;
  }

  .platform-block:hover {
    box-shadow: 0px 4px 8px 0px rgba(170, 184, 255, 0.3);
    cursor: pointer;
  }

  .inner-block {
    font-size: 19px;
    color: #626265;
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }

  .product-block {
    border: 1px solid #f6f7f9;
    height: 84px;
    width: 203px;
    margin-right: 15px;
    border-radius: 4px;
    margin-bottom: 30px;
    transition: all 0.3s;
    cursor: pointer;
  }

  .product-block:hover {
    box-shadow: 0px 4px 8px 0px rgba(170, 184, 255, 0.3);
  }

  .product-icon {
    display: inline-block;
    margin-left: 16px;
    margin-top: 27px;
    width: 30px;
    height: 30px;
  }

  .sdk-block {
    border: 1px solid #f6f7f9;
    height: 104px;
    width: 203px;
    margin-right: 15px;
    border-radius: 4px;
    margin-bottom: 30px;
    transition: all 0.3s;
  }

  .sdk-block:hover {
    box-shadow: 0px 4px 8px 0px rgba(170, 184, 255, 0.3);
  }

  .sdk-icon {
    display: inline-block;
    margin-left: 16px;
    margin-top: 37px;
    width: 30px;
    height: 30px;
  }

  .item-desc {
    font-size: 14px;
    margin-left: 14px;
    margin-top: 7px;
    //color: #9a9a9f;
  }

  .item-oper {
    color: #9a9a9f;
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
  }

  .icon {
    display: inline-block;
    margin-left: 16px;
    margin-top: 17px;
    width: 30px;
    height: 30px;
  }

  .title a:hover {
    color: #1b192f;
  }

  // .mini-title {
  //   font-size: 0.16rem;
  //   margin-bottom: 0.24rem;
  // }

  .links {
    margin-left: 40px;
  }

  .block-active {
    background-color: #495eeb;
    color: white;
  }

  ul {
    padding-top: 0.14rem;
    padding-left: 0.23rem;
    margin-top: 0.12rem;
    border-top: 0.01rem solid #dadada;
    list-style: disc;
  }

  li a,
  li {
    cursor: pointer;
    font-size: 0.14rem;
    font-family: "PingFangSC-Regular";
    font-weight: 400;
    color: rgba(123, 140, 255, 1);
    margin-bottom: 0.18rem;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: #3e57ff;
    }
  }
}

@media screen and (max-width: 576px) {
  .center {
    .title {
      font-size: 0.34rem;
    }

    .sub-title {
      font-size: 0.28rem;
    }

    .name a,
    .name {
      font-size: 0.28rem;
    }

    ul {
      padding-left: 0.36rem;

      li a,
      li {
        font-size: 0.23rem;
      }
    }

    .links {
      .block {
        margin-bottom: 0.2rem;
      }
    }
  }
}
</style>