<template>
  <!-- eslint-disable -->
  <div class="developer-nav">
    <Row class-name="lg-container nav-container">
      <Col
        :lg="0"
        :xs="3"
        v-if="this.acName == 'document' || this.acName == 'platform'"
        class="menu-btn"
      >
        <img @click="switchLeftNav" src="../images/nav/nav-icon02.png" alt="menu" />
      </Col>
      <Col :lg="4" :xs="6" class-name="logo">
        <router-link to="/">
          <img src="../images/nav/logo-d.png" alt="好视通logo" />
        </router-link>
      </Col>
      <Col :lg="3" :xs="0">
        <router-link 
          :style="{'visibility': this.acName !== 'developer' ? 'visible' : 'hidden'}"
          tag="span" 
          class="developer dev-center" 
          to="/developer">
          <span>开发者中心</span>
        </router-link>
      </Col>
      <Col :lg="{ span: 8, offset: 2 }" :xs="0" class-name="menu-container">
        <Menu mode="horizontal" theme="dark" :active-name="acName">
          <MenuItem to="/developer/platform" name="platform">平台介绍</MenuItem>
          <Submenu name="production">
            <template slot="title">
              <span>产品文档</span>
            </template>
            <MenuItem
              name="audio"
              :to="{path: '/developer/document', query: {production: 'audio'}}"
            >音频通信</MenuItem>
            <MenuItem
              name="video"
              :to="{path: '/developer/document', query: {production: 'video'}}"
            >视频通信</MenuItem>
            <MenuItem
              name="share"
              :to="{path: '/developer/document', query: {production: 'screen_share'}}"
            >屏幕共享</MenuItem>
            <MenuItem
              name="signal"
              :to="{path: '/developer/document', query: {production: 'tmsg'}}"
            >信令通道</MenuItem>
            <MenuItem
              name="online"
              :to="{path: '/developer/document', query: {production: 'online'}}"
            >在线</MenuItem>
            <MenuItem
              name="record"
              :to="{path: '/developer/document', query: {production: 'record'}}"
            >云录制</MenuItem>
            <MenuItem
              name="live"
              :to="{path: '/developer/document', query: {production: 'live'}}"
            >云直播</MenuItem>
            <MenuItem
              name="other"
              :to="{path: '/developer/document', query: {production: 'other'}}"
            >其它</MenuItem>
          </Submenu>
          <Submenu name="download">
            <template slot="title">
              <span>下载</span>
            </template>
            <MenuItem 
              name="sdk"
              :to="{path: '/developer/sdk', query: {} }"
            >SDK</MenuItem>
            <MenuItem 
              name="demo"
              :to="{path: '/developer/demo', query: {} }"
            >DEMO</MenuItem>
            <MenuItem 
              name="code"
              :to="{path: '/developer/code', query: {} }"
            >其它</MenuItem>
          </Submenu>
        </Menu>
      </Col>
      <Col :lg="{ span: 5, offset: 2 }" :xs="0" class="tr">
        <a href="http://customer.paas.hst.com/monitor_main/panel" target="_blank">
          <div class="login-btn">登录</div>
        </a>
        <a
          href="http://customer.paas.hst.com/register?userFrom=PaaS%E5%AE%98%E7%BD%91"
          target="_blank"
        >
          <div class="register-btn">注册</div>
        </a>
      </Col>
      <Col :lg="0" :xs="{span: 3, offset: offset}" class="menu-btn">
        <img @click="showMenu = !showMenu" src="../images/nav/nav-icon02.png" alt="menu" />
      </Col>
    </Row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showMenu: false
    };
  },

  computed: {
    acName() {
      return this.$route.name;
    },

    offset() {
      return this.$route.name == "document" || this.$route.name == "platform"
        ? 12
        : 15;
    }
  },

  methods: {
    switchLeftNav() {
      this.$store.commit("switchLeftNav");
    }
  }
};
</script>

<style lang="scss" scoped>
.dev-center {
  padding: 2px 16px;
  background-color: #495EEB; 
  height:24px; 
  border-radius: 12px;
}

.developer-nav {
  position: fixed;
  height: 0.56rem;
  background: #2e2c3f;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.nav-container {
  height: 0.56rem;
  max-width: 13.66rem;
  line-height: 0.56rem;
}

.logo {
  margin-top: 0.13rem;
  padding-left: 0.15rem;
}

.logo img {
  max-width: 1.88rem;
  height: 0.3rem;
  vertical-align: middle;
}

.developer {
  color: #ffffff;
  font-size: 0.14rem;
  font-family: "SourceHanSansCN-Regular";
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer;
}

// .developer:hover {
//   color: #7b8cff;
// }

.menu-item {
  margin-right: 0.44rem;
  line-height: 0.56rem;
  font-size: 0.22rem;
  font-family: "SourceHanSansCN-Regular";
  font-weight: 400;
  border-bottom: 0.02rem solid #495eeb;
}

.ivu-menu-dark {
  background: #2e2c3f;
}

.ivu-menu-horizontal {
  height: 0.56rem;
  line-height: 0.56rem;
}

.ivu-menu-item,
.ivu-menu-submenu {
  font-family: "SourceHanSansCN-Regular";
  font-size: 0.18rem;
}

.ivu-menu-horizontal.ivu-menu-dark:after {
  display: none;
}

.ivu-menu-dark.ivu-menu-horizontal .ivu-menu-item-active,
.ivu-menu-dark.ivu-menu-horizontal .ivu-menu-item:hover,
.ivu-menu-dark.ivu-menu-horizontal .ivu-menu-submenu-active,
.ivu-menu-dark.ivu-menu-horizontal .ivu-menu-submenu:hover {
  color: #7b8cff;
  border-bottom: 0.02rem solid #7b8cff;
}

.ivu-menu-vertical {
  width: 100% !important;
}

.ivu-menu-vertical .ivu-menu-item,
.ivu-menu-vertical .ivu-menu-submenu {
  border-bottom: 1px solid #e6e8f4;
}

.ivu-menu-submenu-title a {
  color: rgba(255, 255, 255, 0.7);
}

::v-deep
  .ivu-menu-horizontal
  .ivu-icon.ivu-icon-ios-arrow-down.ivu-menu-submenu-title-icon {
  display: none !important;
}

.login-btn,
.register-btn {
  cursor: pointer;
  display: inline-block;
  width: 0.8rem;
  text-align: center;
  vertical-align: middle;
  height: 0.32rem;
  line-height: 0.32rem;
  color: #ffffff;
  font-size: 0.16rem;
  font-weight: 500;
  border: 1px solid #495eeb;
  border-radius: 0.24rem;
  transition: all 300ms;
}

.login-btn {
  //background: #7b8cff;
  background: #495EEB;
  border-color: transparent;
  margin-right: 0.16rem;
}

// .login-btn:hover,
// .register-btn:hover {
//   background: #ffffff;
//   color: #495eeb;
//   border: 0px;
// }

.menu-btn img {
  width: 0.56rem;
  vertical-align: top;
  display: inline-block;
}

@media screen and (max-width: 576px) {
  .ivu-menu-submenu-title a {
    color: #000;
  }
}
</style>