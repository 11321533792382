<template>
  <!-- eslint-disable -->
  <div class="price">
    <!-- banner -->
    <div class="banner">
      <img
        :src="isMobile ? banner.mobileImgUrl : banner.imgUrl"
        alt="price"
      >
      <div
        v-if="!isMobile"
        @click="goAnywhere"
        class="banner-btn"
      >免费试用</div>
      <div
        v-if="!isMobile"
        class="banner-btn2"
      >
        <router-link :to="{path: '/developer/code'}">立即体验</router-link>
      </div>
      <a
        v-if="isMobile"
        href="http://customer.paas.hst.com/login"
        class="banner-link"
      ></a>
    </div>
    <!-- 赠送 -->
    <div class="sale">
      <div class="title">注册即赠送测试费用，最高达1000元</div>
      <!-- <div class="sub-title">赠送费用与充值费用具有等价消费能力</div> -->
      <Row
        type="flex"
        justify="space-between"
        class="sale-container"
      >
        <Col
          span="7"
          class="sale-card"
        >
        <div class="main">普通开发者</div>
        <img src="../images/price/price-icon01.png">
        <div class="sub">赠送<span class="money">200元</span></div>
        </Col>
        <Col
          span="7"
          class="sale-card"
        >
        <div class="main">个人开发者</div>
        <img src="../images/price/price-icon02.png">
        <div class="sub">赠送<span class="money">500元</span></div>
        </Col>
        <Col
          span="7"
          class="sale-card"
        >
        <div class="main">企业开发者</div>
        <img src="../images/price/price-icon03.png">
        <div class="sub">赠送<span class="money">1000元</span></div>
        </Col>
      </Row>
      <a
        href="http://customer.paas.hst.com/register?userFrom=PaaS%E5%AE%98%E7%BD%91"
        target="_blank"
      >
        <div class="btn">免费试用</div>
      </a>
    </div>
    <!-- 计费 -->
    <div class="billing">
      <Row
        type="flex"
        justify="space-around"
        class="billing-container"
      >
        <Col
          :lg="6"
          :xs="11"
          v-for="(item, index) in billing"
          :key="index"
          class="billing-card"
        >
        <img
          @click="toMore(item)"
          :src="item.imgUrl"
        >
        </Col>
      </Row>
      <div class="more">点击 <router-link :to="{name: 'platform', params: {area: 'price'}}">这里</router-link> 了解详细计费说明，更多价格相关问题请咨询0755-83885517</div>
      <a
        href="http://customer.paas.hst.com/register?userFrom=PaaS%E5%AE%98%E7%BD%91"
        target="_blank"
      >
        <div class="btn">免费试用</div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      banner: {
        imgUrl: require("../images/price/price-banner01.png"),
        mobileImgUrl: require("../images/price/price-banner02.png")
      },
      billing: [
        {
          imgUrl: require("../images/price/price-pic01.png")
        },
        {
          imgUrl: require("../images/price/price-pic02.png")
        },
        {
          imgUrl: require("../images/price/price-pic03.png")
        },
        {
          imgUrl: require("../images/price/price-pic04.png")
        },
        {
          imgUrl: require("../images/price/price-pic05.png")
        },
        {
          imgUrl: require("../images/price/price-pic06.png")
        },
        {
          imgUrl: require("../images/price/price-pic07.png")
        },
        {
          imgUrl: require("../images/price/price-pic08.png"),
          go: true
        }
      ]
    };
  },

  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  },

  methods: {
    goAnywhere() {
      window.open(
        "http://customer.paas.hst.com/register?userFrom=PaaS%E5%AE%98%E7%BD%91"
      );
    },

    toMore(item) {
      if (!item.go) return;
      window.open("http://customer.paas.hst.com/monitor_main/panel");
    }
  }
};
</script>

<style scoped>
.banner {
  position: relative;
  margin-bottom: 0.8rem;
}

.banner-btn {
  position: absolute;
  cursor: pointer;
  width: 1.44rem;
  height: 0.42rem;
  border-radius: 0.21rem;
  background: #ff9f29;
  color: #ffffff;
  font-size: 0.18rem;
  font-weight: 500;
  line-height: 0.42rem;
  top: 64%;
  left: 19%;
  transition: all 0.3s;
}

.banner-btn:hover {
  background: #ff8d00;
}

.banner-btn:active {
  background: #d17300;
}

.banner-btn2 {
  position: absolute;
  cursor: pointer;
  width: 1.44rem;
  height: 0.42rem;
  border: 0.01rem solid #ff9f29;
  border-radius: 0.21rem;
  font-size: 0.18rem;
  font-weight: 500;
  line-height: 0.42rem;
  top: 64%;
  left: 28%;
  transition: all 0.3s;
}

.banner-btn2 a {
  color: #ffffff;
}

.banner-btn2:hover {
  border-color: #ff8d00;
}

.banner-btn2:hover a {
  color: #ff8d00;
}

.banner-btn2:active {
  border-color: #d17300;
}

.banner-btn2:active a {
  color: #d17300;
}

.title {
  font-size: 0.32rem;
  font-weight: 500;
  color: #575a71;
  margin-bottom: 0.84rem;
}

.sub-title {
  font-size: 0.28rem;
  font-family: "SourceHanSansCN-Regular";
  font-weight: 400;
  color: #9a9caa;
  margin-bottom: 1.09rem;
}

.btn {
  margin: 0 auto;
  margin-top: 0.6rem;
  margin-bottom: 0.8rem;
  cursor: pointer;
  max-width: 1.44rem;
  height: 0.42rem;
  line-height: 0.42rem;
  background: #ff9f29;
  border-radius: 0.21rem;
  color: #ffffff;
  font-size: 0.18rem;
  font-weight: 500;
}

.sale {
  margin-bottom: 0.81rem;
}

.sale .sale-container {
  max-width: 9rem;
  margin: 0 auto;
}

.sale .sale-card .main {
  font-size: 0.28rem;
  font-weight: 500;
  margin-bottom: 0.26rem;
  color: #9296b8;
}

.sale .sale-card .sub {
  font-size: 0.24rem;
  font-family: "SourceHanSansCN-Bold";
  font-weight: bold;
  color: #a8a0a0;
}

.sale .sale-card .sub .money {
  font-size: 0.32rem;
  font-family: "SourceHanSansCN-Bold";
  font-weight: bold;
  color: #f08383;
}

.sale .sale-card img {
  display: inline-block;
  max-width: 1.6rem;
  margin-bottom: 0.09rem;
}

.billing {
  margin-bottom: 0.89rem;
}

.billing .billing-container {
  max-width: 10rem;
  margin: 0 auto;
  margin-bottom: 0.51rem;
}

.billing .billing-card {
  padding: 0 0.05rem 0.05rem 0;
}

.billing .billing-card img {
  transition: all 0.3s;
}

.billing .billing-card img:hover {
  box-shadow: 0px 8px 16px 0px rgba(170, 184, 255, 0.3);
}

.billing .more {
  font-size: 0.16rem;
  font-family: "PingFangSC-Regular";
  font-weight: 400;
  color: #535381;
}

.billing .more a {
  color: #0064ff;
  text-decoration: underline;
}

.billing .btn {
  margin-top: 0.35rem;
}

@media screen and (max-width: 576px) {
  .banner {
    position: relative;
  }

  .banner-link {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .btn {
    line-height: 0.45rem;
  }

  .billing .billing-container {
    max-width: 7rem;
  }

  .billing .billing-card {
    margin-bottom: 0.3rem;
  }
}
</style>