<template>
  <!-- eslint-disable -->
  <div class="base-nav">
    <Row class-name="lg-container nav-container">
      <Col
        :lg="4"
        :xs="11"
        class-name="logo"
      >
      <router-link to="/">
        <img
          src="../images/nav/logo.png"
          alt="好视通logo"
        >
      </router-link>
      </Col>
      <Col
        :lg="{ span: 8, offset: 2 }"
        :xs="0"
        class-name="menu-container"
      >
      <Menu
        mode="horizontal"
        theme="light"
        :active-name="acName"
      >
        <MenuItem
          to="/"
          name="home"
        >
        <router-link to="/">首页</router-link>
        </MenuItem>
        <Submenu name="production">
          <template slot="title">
            <span>
              <router-link to="/production">产品</router-link>
            </span>
          </template>
          <MenuItem
            name="audio"
            to="/production/audio"
          >音频通信
          </MenuItem>
          <MenuItem
            name="video"
            to="/production/video"
          >视频通信
          </MenuItem>
          <MenuItem
            name="share"
            to="/production/share"
          >屏幕共享
          </MenuItem>
          <MenuItem
            name="rec"
            to="/production/rec"
          >云录制
          </MenuItem>
          <MenuItem
            name="online"
            to="/production/online"
          >在线
          </MenuItem>
          <MenuItem
            name="signal"
            to="/production/signal"
          >信令通道
          </MenuItem>
          <MenuItem
            name="private"
            to="/production/private"
          >私有云
          </MenuItem>
        </Submenu>
        <MenuItem
          to="/price"
          name="price"
        >
        <router-link to="/price">价格</router-link>
        </MenuItem>
        <MenuItem
          to="/developer"
          name="developer"
        >
        <router-link to="/developer">开发者</router-link>
        </MenuItem>
      </Menu>
      </Col>
      <Col
        :lg="{ span: 8, offset: 2 }"
        :xs="10"
      >
       <a
        href="http://www.hst.com"
        target="_blank"
      >
        <div class="back-btn">
          返回官网
        </div>
      </a>

      <a
        href="http://customer.paas.hst.com/monitor_main/panel"
        target="_blank"
      >
        <div class="login-btn"><img
            src="../images/nav/login.png"
            alt="login"
          ></div>
      </a>
      <a
        href="http://customer.paas.hst.com/register?userFrom=PaaS%E5%AE%98%E7%BD%91"
        target="_blank"
      >
        <div class="register-btn"><img
            src="../images/nav/register.png"
            alt="register"
          ></div>
      </a>
      </Col>
      <Col
        :lg="0"
        :xs="{span: 3}"
        class="menu-btn"
      >
      <img
        @click="showMenu = !showMenu"
        src="../images/nav/nav-icon01.png"
        alt="menu"
      >
      </Col>
    </Row>
    <transition name="fade">
      <div
        class="menu-list"
        v-show="showMenu"
      >
        <Menu
          theme="light"
          @on-select="showMenu=false"
          :active-name="acName"
        >
          <MenuItem
            to="/"
            name="home"
          >
          <router-link to="/">首页</router-link>
          </MenuItem>
          <Submenu name="production">
            <template slot="title">
              <span>
                <router-link to="/production">产品</router-link>
              </span>
            </template>
            </MenuItem>
            <MenuItem
              name="audio"
              to="/production/audio"
            >音频通信
            </MenuItem>
            <MenuItem
              name="video"
              to="/production/video"
            >视频通信
            </MenuItem>
            <MenuItem
              name="share"
              to="/production/share"
            >屏幕共享
            </MenuItem>
            <MenuItem
              name="rec"
              to="/production/rec"
            >云录制
            </MenuItem>
            <MenuItem
              name="online"
              to="/production/online"
            >在线
            </MenuItem>
            <MenuItem
              name="signal"
              to="/production/signal"
            >信令通道
            </MenuItem>
            <MenuItem
              name="private"
              to="/production/private"
            >私有云
            </MenuItem>
          </Submenu>
          <MenuItem
            to="/price"
            name="price"
          >
          <router-link to="/price">价格</router-link>
          </MenuItem>
          <MenuItem
            to="/developer"
            name="developer"
          >
          <router-link to="/developer">开发者</router-link>
          </MenuItem>
        </Menu>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showMenu: false
    };
  },

  computed: {
    acName() {
      return this.$route.name;
    }
  }
};
</script>

<style lang='scss' scoped>
.base-nav {
  position: fixed;
  height: 0.56rem;
  background: #ffffff;
  box-shadow: 0rem 0.01rem 0rem 0rem rgba(130, 140, 189, 1);
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
}
.back-btn {
  display: inline-block;
  width: 0.9rem;
  vertical-align: middle;
  margin-right: 0.16rem;
  width: 1.1rem;
  height: 0.36rem;
  border-radius: 24px 24px 24px 24px;
  border: 1px solid rgba(73, 94, 235, 0.5);
  font-size: .18rem;
  font-weight: 700;
  color: #393939;
  line-height: .35rem;
}

.nav-container {
  height: 0.56rem;
  line-height: 0.56rem;
}

.logo {
  margin-top: 0.13rem;
  padding-left: 0.15rem;
}

.logo img {
  max-width: 1.88rem;
  height: 0.3rem;
  vertical-align: middle;
}

.menu-item {
  margin-right: 0.44rem;
  line-height: 0.56rem;
  font-size: 0.16rem;
  font-family: "SourceHanSansCN-Regular";
  font-weight: 400;
  border-bottom: 0.02rem solid #495eeb;
}

.ivu-menu-horizontal {
  height: 0.56rem;
  line-height: 0.56rem;
}

.ivu-menu-item,
.ivu-menu-submenu {
  font-family: "SourceHanSansCN-Regular";
  font-size: 0.16rem;
}

.ivu-menu-horizontal.ivu-menu-light:after {
  display: none;
}

.ivu-menu-light.ivu-menu-horizontal .ivu-menu-item-active,
.ivu-menu-light.ivu-menu-horizontal .ivu-menu-item:hover,
.ivu-menu-light.ivu-menu-horizontal .ivu-menu-submenu-active,
.ivu-menu-light.ivu-menu-horizontal .ivu-menu-submenu:hover {
  color: #495eeb;
  border-bottom: 0.02rem solid #495eeb;
}

.ivu-menu-vertical {
  width: 100% !important;
}

.ivu-menu-vertical .ivu-menu-item,
.ivu-menu-vertical .ivu-menu-submenu {
  border-bottom: 1px solid #e6e8f4;
}

::v-deep
  .ivu-menu-horizontal
  .ivu-icon.ivu-icon-ios-arrow-down.ivu-menu-submenu-title-icon {
  display: none !important;
}

.login-btn,
.register-btn {
  cursor: pointer;
  display: inline-block;
  width: 0.9rem;
  vertical-align: middle;
  // height: 0.32rem;
  // line-height: 0.32rem;
  // font-size: 0.18rem;
  // font-weight: 500;
  // border: 1px solid #495eeb;
  // border-radius: 0.24rem;
}

.login-btn {
  // background: #495eeb;
  // color: #ffffff;
  border-color: transparent;
  margin-right: 0.16rem;
  transition: all 300ms;
}

.login-btn:hover {
  // background: #ffffff;
  // color: #495eeb;
  // border-color: #495eeb;
}

.menu-btn img {
  width: 0.48rem;
  display: inline-block;
}

@media screen and (max-width: 576px) {
  .login-btn,
  .register-btn {
    font-size: 0.1rem;
  }
}
</style>