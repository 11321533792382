<template>
  <!-- eslint-disable -->
  <div v-if="!showDocumentNav" class="doc">
    <div :class="['left-nav', showLeftNav ? 'show' : '']">
      <div class="icon">
        <img :src="icon">
        <h3>{{this.title}}</h3>
      </div>
      <Menu mode="vertical" theme="light" style="width: 100%;" :active-name="this.enArea" @on-select="changeArea">
        <MenuItem name="product_summary">
          <Icon type="ios-paper" size="23"/>
          产品介绍
        </MenuItem>
        <MenuItem name="prepare">
          <Icon type="ios-paper" size="23"/>
          准备工作
        </MenuItem>
        <MenuItem name="quick_start">
          <Icon type="ios-paper" size="23"/>
          快速开始
        </MenuItem>
        <MenuItem v-show="production != 'other'" name="advance_func">
          <Icon type="ios-paper" size="23"/>
          进阶教程
        </MenuItem>
        <MenuItem name="api">
          <Icon type="ios-paper" size="23"/>
          API参考
        </MenuItem>
      </Menu>
    </div>
    <div class="doc-container">
      <div class="nav-bar">
        <Breadcrumb>
          <BreadcrumbItem>产品文档</BreadcrumbItem>
          <BreadcrumbItem>{{this.title}}</BreadcrumbItem>
          <BreadcrumbItem>{{this.cnArea}}</BreadcrumbItem>
          <BreadcrumbItem v-if="showAlert">{{this.system}}</BreadcrumbItem>
        </Breadcrumb>
      </div>
      <div v-if="showTypeNav" class="doc-type">
        <Menu mode="horizontal" theme="light" :active-name="this.system" @on-select="changeSystem">
          <MenuItem v-for="(item, index) in docType" :key="index" :name="item.url">
            {{item.name}}
          </MenuItem>
        </Menu>
      </div>
      <div class="content">
        <!-- iOS和macOS目前还没有统一api文档 -->
        <iframe
          v-if="this.enArea == 'api'
          && this.production != 'record'
          && this.production != 'live'
          && this.system != 'web'
          && this.system != 'windows'
          && this.system != 'android'
          && this.system != 'wechat'
          && this.system != 'harmony'"
          id="api"
          title="api"
          scrolling="auto"
          marginWidth=0
          marginHeight=0
          frameBorder=0
          :src="'http://paas.hst.com/api/' + this.system + '_api'"
        >
        </iframe>

        <!-- 加载中 -->
        <div v-if="this.loading" class="load-container">
          <div class="load load1"></div>
          <div class="load load2"></div>
          <div class="load"></div>
        </div>

        <!-- api文档 -->
        <vue-markdown
          v-if="(this.enArea != 'api' && !this.loading) ||
          (this.enArea == 'api' && this.system == 'web') ||
          (this.enArea == 'api' && this.system == 'wechat') ||
          (this.enArea == 'api' && this.system == 'windows') ||
          (this.enArea == 'api' && this.system == 'android') ||
          (this.production == 'record') ||
          (this.production == 'live') ||
          (this.enArea == 'api' && this.system == 'harmony')"
          :source="docContent"
          @rendered="rendered"
        ></vue-markdown>
      </div>
    </div>
    <div class="right-nav">
      <Anchor show-ink>
        <AnchorLink
          v-for="(item, index) in anchor"
          :key="index"
          :href="item.href"
          :title="item.title"
          :scroll-offset="60"
        ></AnchorLink>
      </Anchor>
    </div>
  </div>

  <div v-else class="doc-nav">
    <Row type="flex">
      <Col :lg="8" :xs="12" class="card-container" v-for="(item, index) in doc" :key="index">
      <div class="doc-card">
        <div class="thumbnail">
          <img :src="item.icon" :alt="item.title">
        </div>
        <div @click="gotoDocDetail($event, item)" class="info">
          <div class="title">{{item.title}}</div>
          <ul>
            <div>
              <li data-area="product_summary">产品介绍</li>
              <li data-area="prepare">准备工作</li>
              <li data-area="quick_start">快速开始</li>
            </div>
            <div>
              <li data-area="advance_func">进阶教程</li>
              <li data-area="api">API参考</li>
            </div>
          </ul>
        </div>
      </div>
      </Col>
    </Row>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown";
import Prism from "prismjs";
import { docUrl } from '../../service/api';

export default {
  components: { VueMarkdown },

  data() {
    return {
      docContent: "",
      docType: [
        { name: "Windows", url: "windows" },
        { name: "Android", url: "android" },
        { name: "iOS", url: "ios" },
        { name: "macOS", url: "mac" },
        { name: "Web", url: "web" },
        { name: "WeChat", url: "wechat" },
        { name: "Harmony", url: "harmony" }
      ],
      doc: [
        {
          icon: require("../../images/doc/doc-icon01.png"),
          title: "音频通信",
          production: "audio"
        },
        {
          icon: require("../../images/doc/doc-icon02.png"),
          title: "视频通信",
          production: "video"
        },
        {
          icon: require("../../images/doc/doc-icon03.png"),
          title: "屏幕共享",
          production: "screen_share"
        },
        {
          icon: require("../../images/doc/doc-icon04.png"),
          title: "信令通道",
          production: "tmsg"
        },
        {
          icon: require("../../images/doc/doc-icon05.png"),
          title: "在线",
          production: "online"
        },
        {
          icon: require("../../images/doc/doc-icon06.png"),
          title: "云录制",
          production: "record"
        },
        {
          icon: require("../../images/doc/doc-icon09.png"),
          title: "云直播",
          production: "live"
        },
        {
          icon: require("../../images/doc/doc-icon08.png"),
          title: "其它",
          production: "other"
        }
      ],
      anchor: [],
      loading: true,
      // icon: require("../../images/doc/doc-icon02.png"),
      // title: "",
      production: "",
      enArea: "product_summary",
      system: "windows",
      showTypeNav: true,
      showDocumentNav: true,
      showAlert: true
    };
  },

  computed: {
    cnArea() {
      switch (this.enArea) {
        case "product_summary":
          return "产品介绍";
        case "prepare":
          return "准备工作";
        case "quick_start":
          return "快速开始";
        case "advance_func":
          return "进阶教程";
        case "api":
          return "API参考";
        default:
          return "";
      }
    },

    title() {
      switch (this.production) {
        case "audio":
          return "音频通信";
        case "video":
          return "视频通信";
        case "screen_share":
          return "屏幕共享";
        case "tmsg":
          return "信令通道";
        case "online":
          return "在线";
        case "record":
          return "云录制";
        case "live":
          return "云直播";
        case "other":
          return "其它";
        default:
          return "";
      }
    },

    icon() {
      switch (this.production) {
        case "audio":
          return require("../../images/doc/doc-icon01.png");
        case "video":
          return require("../../images/doc/doc-icon02.png");
        case "screen_share":
          return require("../../images/doc/doc-icon03.png");
        case "tmsg":
          return require("../../images/doc/doc-icon04.png");
        case "online":
          return require("../../images/doc/doc-icon05.png");
        case "record":
          return require("../../images/doc/doc-icon06.png");
        case "live":
          return require("../../images/doc/doc-icon09.png");
        case "other":
          return require("../../images/doc/doc-icon08.png");
        default:
          return "";
      }
    },

    showLeftNav() {
      return this.$store.state.showLeftNav;
    }
  },

  mounted() {
    // document.body.addEventListener('click', e => {
    //   console.log('e.target', e.target);
    // })

    if (this.$route.query.production) {
      this.showDocumentNav = false;
      this.production = this.$route.query.production;
    } else {
      this.showDocumentNav = true;
    }

    if (this.$route.params.area) {
      this.enArea = this.$route.params.area;
    }

    // if (this.$route.params.title) {
    //   this.title = this.$route.params.title;
    // }

    this.switchNav();
    this.toWhere();
  },

  beforeRouteUpdate(to, from, next) {
    if (to.query.production) {
      this.showDocumentNav = false;
      this.production = to.query.production;
    } else if (to.hash) {
      next();
      return;
    } else {
      this.showDocumentNav = true;
    }
    this.toWhere();
    next();
  },

  methods: {
    rendered() {
      this.$nextTick(() => {
        this.anchor = [];
        let h2Arr = document.querySelectorAll("h2");

        h2Arr.forEach(item => {
          this.attachANode(item);
        });

        Prism.highlightAll();
      });
    },

    gotoDocDetail(e, item) {
      this.$router.push({ query: { production: item.production } });
      // this.icon = item.icon;
      // this.title = item.title;
      this.production = item.production;
      this.enArea = e.target.dataset.area || "product_summary";
      this.showDocumentNav = false;
      this.switchNav();
      this.toWhere();
    },

    toWhere() {
      if (!this.production) return;
      
      if (this.enArea == "product_summary") {
        this.getAndDealDoc(this.production + "/" + this.enArea + ".md");
      } else if (this.enArea == "prepare") {
        if (this.production == "record") {
          this.getAndDealDoc("platform/" + this.enArea + "_record" + ".md");
        } else if (this.production == "live") {
          this.getAndDealDoc("platform/" + this.enArea + "_live" + ".md");
        } else if (this.production == "other") {
          this.getAndDealDoc("platform/" + this.enArea + "_other" + ".md");
        } else {
          this.getAndDealDoc(
            "platform/" + this.enArea + "_" + this.system + ".md"
          );
        }
      } else if (this.enArea == "api") {
        if (this.production == "record" || this.production == "live" || this.production == "other") {
          this.getAndDealDoc("platform/api_" + this.production + ".md");
        } else {
          this.getAndDealDoc("platform/api_" + this.system + ".md");
        }
      } else {
        if (this.production == "record" || this.production == "live" || this.production == "other") {
          this.getAndDealDoc(this.production + "/" + this.enArea + ".md");
        } else {
          this.getAndDealDoc(this.production + "/" + this.enArea + "_" + this.system + ".md");
        }
      }
    },

    getAndDealDoc(url) {
      this.loading = true;

      fetch(docUrl + url, {method: "GET", mode: "cors"}).then(res => {
        if (res.status === 200) {
          return res.text();
        } else {
          throw new Error(res.statusText)
        }
      }).then(data=>{
        this.docContent = data
        this.loading = false
      }).catch(()=> {
        this.$Message.warning({
            content: "暂无此项内容"
          });
          this.docContent = "";
          this.loading = false;
      })
    },

    changeSystem(name) {
      this.system = name;
      if (this.enArea == "api" 
        && this.system != "web" 
        && this.system != "windows" 
        && this.system != "wechat" 
        && this.system != "android"
        && this.system != "harmony"
      ) 
        return;

      this.toWhere();
    },

    changeArea(name) {
      this.enArea = name;

      this.switchNav();

      this.toWhere();
    },

    switchNav() {
      if (this.enArea == "product_summary") {
        this.showTypeNav = false;
        this.showAlert = false;
      } else {
        this.showTypeNav = true;
        this.showAlert = true;
      }

      if (this.enArea == "api") {
        this.showTypeNav = true;
        this.showAlert = false;
      }

      if (this.production == "record") {
        this.showTypeNav = false;
        this.showAlert = false;
      }

      if (this.production == "live") {
        this.showTypeNav = false;
        this.showAlert = false;
      }

      if (this.production == "other") {
        this.showTypeNav = false;
        this.showAlert = false;
      }
    },

    /**
     * 在node节点前添加一个a标签
     */
    attachANode(node) {
      let id = node.innerText.replace(/\s+/g, "_"); // 替换空格
      node.setAttribute("id", id);
      let a = document.createElement("a");
      let href = "#" + node.innerText.replace(/\s+/g, "_");
      a.setAttribute("href", href);
      node.insertAdjacentElement("beforebegin", a);

      this.setAnchor(node.innerText, href);
    },

    /**
     * 设置锚点对象数组
     */
    setAnchor(title, href) {
      let tmp = {};
      tmp.title = title;
      tmp.href = href;
      this.anchor.push(tmp);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../node_modules/prismjs/themes/prism-okaidia.css";
@import "../../css/document.css";
@import "../../css/loading.scss";

.md-table-class {
  font-size: 16px
}

.doc,
.doc-nav {
  max-width: 13.66rem;
  margin: 0 auto;
  text-align: left;
  min-height: 93vh;
  background: #ffffff;
}

.left-nav,
.right-nav,
.doc-container {
  display: inline-block;
  vertical-align: top;
}

.left-nav {
  width: 15.2%;
  max-width: 2.07rem;
}

.left-nav .icon {
  height: 0.8rem;
  background: rgba(243, 245, 254, 1);
  text-align: center;
  line-height: 0.8rem;
}

.left-nav img {
  display: inline-block;
  max-width: 0.6rem;
}

.left-nav h3 {
  display: inline-block;
  font-family: "SourceHanSansCN-Medium";
  font-weight: 500;
  font-size: 0.2rem;
  color: #3b51a2;
}

.right-nav {
  width: 15%;
  position: fixed;
  max-width: 2rem;
}

.doc-container {
  width: 69.8%;
  max-width: 10.04rem;
  min-height: 93vh;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.nav-bar,
.doc-type {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.nav-bar {
  height: 0.4rem;
  line-height: 0.4rem;
}

.ivu-menu-horizontal,
.doc-type {
  height: 0.46rem;
  line-height: 0.46rem;
  // margin-bottom: 0.16rem;
  z-index: 0;
}

.content {
  padding: 0 0.2rem;
}

.ivu-menu-horizontal,
.ivu-breadcrumb span:first-child {
  margin-left: 0.2rem;
}

.ivu-menu-vertical .ivu-menu-item {
  font-family: "PingFangSC-Medium";
  font-weight: 500;
}

.ivu-anchor-link {
  font-size: 0.14rem;
}

.content {
  overflow: hidden;
  margin-bottom: 0.2rem;
}

.notice {
  display: inline-block;
  min-width: 50%;
  margin-top: 0.16rem;
}

.doc-nav .card-container {
  padding: 0.16rem 0.2rem;
}

.doc-nav .doc-card {
  padding: 0.4rem 0.1rem 0.4rem 0.36rem;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.09);
  border-radius: 2px;
  border: 1px solid rgba(233, 233, 233, 1);
}

.doc-card .thumbnail,
.doc-card .info {
  display: inline-block;
}

.doc-card .thumbnail {
  vertical-align: top;
  width: 30%;

  img {
    max-width: 1.2rem;
    display: inline-block;
  }
}

.doc-card .info {
  width: 70%;
  padding-left: 0.45rem;

  .title {
    cursor: pointer;
    font-size: 0.2rem;
    font-family: "PingFangSC-Medium";
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 0.27rem;
    transition: all 0.3s;

    &:hover {
      color: #3e57ff;
    }
  }

  ul {
    list-style-type: disc;
    padding-left: 0.18rem;

    div {
      display: inline-block;
      vertical-align: top;
      width: 50%;

      &:first-child {
        padding-right: 0.2rem;
      }
    }
  }

  li {
    cursor: pointer;
    font-size: 0.16rem;
    margin-bottom: 0.18rem;
    color: #7b8cff;
    transition: color 0.3s;
    &:hover {
      color: #3e57ff;
    }
  }
}

#api {
  position: relative;
  display: block;
  width: 106.3%;
  min-height: 83vh;
  overflow: hidden;
  border: 0;
  margin: 0 -0.2rem;
}

@media screen and (max-width: 576px) {
  .right-nav {
    display: none;
    width: 0;
  }

  .doc-container {
    width: 100%;
    margin-top: 0.1rem;
    border: 0;
  }

  .left-nav {
    position: fixed;
    width: 3rem;
    max-width: 3rem;
    border-right: 0.01rem solid rgba(233, 233, 233, 1);
    background: #ffffff;
    top: 0.56rem;
    left: -8rem;
    bottom: 0;
    transition: all 0.3s ease-in;
    z-index: 90;
  }

  .doc-type * {
    padding: 0 0.05rem;
  }

  #api {
    height: 100vh;
  }

  .left-nav.show {
    display: block;
    left: 0;
  }

  .ivu-breadcrumb {
    font-size: 0.14rem;
  }

  .doc-card .thumbnail img {
    padding-right: 0.1rem;
  }

  .doc-card .info {
    padding-left: 0.6rem;

    .title {
      margin-left: -0.1rem;
    }

    ul {
      div {
        display: block;
        width: 100%;
      }
    }
  }
}
</style>