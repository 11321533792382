<template>
  <!-- eslint-disable -->
  <div class="production">
    <!-- 轮播 -->
    <div class="banner">
      <Carousel
        arrow="never"
        dots="none"
        loop
      >
        <CarouselItem
          v-for="(item, index) in banner"
          :key="index"
        >
          <img :src="isMobile ? item.mobileImgUrl : item.imgUrl">
        </CarouselItem>
      </Carousel>
      <div
        v-if="!isMobile"
        @click="goAnywhere"
        class="banner-btn1"
      >免费试用</div>
      <div
        v-if="!isMobile"
        class="banner-btn2"
      >
        <router-link :to="{path: '/developer/code'}">立即体验</router-link>
      </div>
      <a
        v-if="isMobile"
        href="http://customer.paas.hst.com/login"
        class="banner-link"
      ></a>
    </div>
    <!-- 产品 -->
    <div class="our-pro lg-container">
      <div class="title">我们的产品</div>
      <Row
        type="flex"
        justify="space-around"
        class="our-container"
      >
        <Col
          :lg="7"
          :xs="10"
          class="our-card"
          v-for="(item, index) in our"
          :key="index"
        >
        <div @click="gotoDoc(item)">
          <div class="thumbnail">
            <img
              :src="item.imgUrl"
              :alt="item.title"
            >
          </div>
          <div class="description">
            <div class="main">{{item.title}}</div>
          </div>
        </div>
        </Col>
      </Row>
    </div>
    <!-- code -->
    <div class="code lg-container">
      <div class="title">跨平台，更易集成</div>
      <Row
        type="flex"
        justify="space-around"
        class="code-container"
      >
        <Col
          :lg="6"
          :xs="9"
          class="code-left"
        >
        <div
          :class="['code-title', curCode==index ? 'active' : '']"
          v-for="(item, index) in code"
          :key="index"
          @click="curCode=index"
        ><span class="icon"><img
              :src="item.icon"
              :alt="item.title"
            ></span>{{item.title}}</div>
        </Col>
        <Col
          :lg="12"
          :xs="15"
          class="code-center"
        >
        <div
          class="code-area"
          v-for="(item, index) in code"
          :key="index"
          v-show="curCode==index"
        >
          <img
            :src="item.imgUrl"
            :alt="item.title"
          >
        </div>
        </Col>
        <Col
          :lg="6"
          :xs="0"
          class="code-right"
        >
        <div class="strong">简单，但很强大</div>
        <p>提供跨平台SDK，满足客户多系统、多语言、多平台的集成需求。</p>
        <p>致力于实现更加简洁和强大的API，用更少的代码提供更多的功能，让开发者更简单、更高效。</p>
        </Col>
      </Row>
    </div>
    <!-- 部署 -->
    <div class="deploy">
      <div class="title">部署更灵活</div>
      <Row
        type="flex"
        justify="space-around"
        class="deploy-container"
      >
        <Col
          :lg="11"
          :xs="22"
          class="deploy-card"
          v-for="(item, index) in deploy"
          :key="index"
        >
        <div class="description">
          <div class="main">{{item.title}}</div>
          <div class="sub">{{item.description}}</div>
        </div>
        <div class="thumbnail">
          <img
            :src="item.imgUrl"
            :alt="item.title"
          >
        </div>
        </Col>
      </Row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      banner: [
        {
          imgUrl: require("../../images/production/production-banner01.png"),
          mobileImgUrl: require("../../images/production/production-banner02.png")
        }
      ],
      our: [
        {
          title: "音频通信",
          name: "audio",
          imgUrl: require("../../images/production/production-icon01.png")
        },
        {
          title: "视频通信",
          name: "video",
          imgUrl: require("../../images/production/production-icon02.png")
        },
        {
          title: "屏幕共享",
          name: "share",
          imgUrl: require("../../images/production/production-icon03.png")
        },
        {
          title: "云录制",
          name: "rec",
          imgUrl: require("../../images/production/production-icon04.png")
        },
        {
          title: "在线",
          name: "online",
          imgUrl: require("../../images/production/production-icon05.png")
        },
        {
          title: "信令通道",
          name: "signal",
          imgUrl: require("../../images/production/production-icon06.png")
        }
      ],
      code: [
        {
          title: "Windows(C++)",
          icon: require("../../images/production/production-icon07.svg"),
          imgUrl: require("../../images/production/c++.png")
        },
        {
          title: "Windows(C#)",
          icon: require("../../images/production/production-icon07.svg"),
          imgUrl: require("../../images/production/ch.png")
        },
        {
          title: "iOS",
          icon: require("../../images/production/production-icon08.svg"),
          imgUrl: require("../../images/production/ios.png")
        },
        {
          title: "Android",
          icon: require("../../images/production/production-icon09.svg"),
          imgUrl: require("../../images/production/android.png")
        },
        {
          title: "macOS",
          icon: require("../../images/production/production-icon10.svg"),
          imgUrl: require("../../images/production/macOS.png")
        },
        {
          title: "Web",
          icon: require("../../images/production/production-icon11.svg"),
          imgUrl: require("../../images/production/web.png")
        },
        {
          title: "微信小程序",
          icon: require("../../images/production/production-icon12.svg"),
          imgUrl: require("../../images/production/wx.png")
        }
      ],
      deploy: [
        {
          title: "公有云",
          description: "随时随地接入，更开放的云",
          imgUrl: require("../../images/production/production-pic01.png")
        },
        {
          title: "私有云",
          description: "授权私密访问，更安全的云",
          imgUrl: require("../../images/production/production-pic02.png")
        }
      ],
      curCode: 0
    };
  },

  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  },

  methods: {
    gotoDoc(item) {
      console.log("item", item);
      this.$router.push({
        name: item.name
      });
    },

    goAnywhere() {
      window.open("http://customer.paas.hst.com/register?userFrom=PaaS%E5%AE%98%E7%BD%91");
    }
  }
};
</script>

<style scoped>
.banner {
  position: relative;
  width: 100%;
  margin-bottom: 0.64rem;
}

.banner-btn1 {
  position: absolute;
  cursor: pointer;
  width: 1.44rem;
  height: 0.42rem;
  border-radius: 0.21rem;
  background: #ff9f29;
  color: #ffffff;
  font-size: 0.18rem;
  font-weight: 500;
  line-height: 0.42rem;
  top: 65%;
  left: 50.5%;
  transition: all 0.3s;
}

.banner-btn1:hover {
  background: #ff8d00;
}

.banner-btn1:active {
  background: #d17300;
}

.banner-btn2 {
  position: absolute;
  cursor: pointer;
  width: 1.44rem;
  height: 0.42rem;
  border: 0.01rem solid #ff9f29;
  border-radius: 0.21rem;
  font-size: 0.18rem;
  font-weight: 500;
  line-height: 0.42rem;
  top: 65%;
  left: 59.5%;
  transition: all 0.3s;
}

.banner-btn2 a {
  color: #ffffff;
}

.banner-btn2:hover {
  border-color: #ff8d00;
}

.banner-btn2:hover a {
  color: #ff8d00;
}

.banner-btn2:active {
  border-color: #d17300;
}

.banner-btn2:active a {
  color: #d17300;
}

.title {
  font-size: 0.32rem;
  font-weight: 500;
  color: #575a71;
  margin-bottom: 0.6rem;
}

.title::after {
  content: "";
  display: block;
  width: 0.4rem;
  height: 0.04rem;
  background: #495eeb;
  margin: 0.1rem auto;
  margin-top: 0.15rem;
}

.our-pro {
  margin-bottom: 0.79rem;
}

.our-pro .our-container {
  max-width: 8rem;
  margin: 0 auto;
}

.our-pro .our-card {
  cursor: pointer;
  border-radius: 0.08rem;
  border: 0.01rem solid rgba(199, 205, 218, 1);
  padding: 0.22rem 0 0.29rem;
  margin-bottom: 0.2rem;
  transition: all 0.3s;
}

.our-pro .our-card:hover {
  box-shadow: 0px 8px 16px 0px rgba(170, 184, 255, 0.3);
}

.our-pro .thumbnail img {
  max-width: 0.7rem;
  display: inline-block;
  margin-bottom: 0.2rem;
}

.our-pro .main {
  font-size: 0.22rem;
  font-family: "PingFangSC-Medium";
  font-weight: 500;
  color: #535381;
}

.code {
  margin-bottom: 0.74rem;
}

.code .code-container {
  max-width: 11.5rem;
  margin: 0 auto;
  text-align: left;
}

.code .code-left {
  border: 0.01rem solid #c7cdda;
}

.code .code-left img {
  max-width: 0.4rem;
  margin-right: 0.14rem;
}

.code .code-title {
  cursor: pointer;
  padding: 0.1rem;
  border-left: 0.08rem solid transparent;
  font-size: 0.22rem;
  font-weight: 500;
  opacity: 0.7;
  color: #3b51a2;
  transition: all 300ms;
}

.code .code-title.active {
  border-left: 0.04rem solid #495eeb;
  color: #3b51a2;
  opacity: 1;
}

.code .code-center {
  border: 0.01rem solid #c7cdda;
  border-left: 0;
  padding: 0.18rem 0.36rem 0.26rem 0.36rem;
}

.code img {
  display: inline-block;
  max-width: 100%;
}

.code .code-right {
  border: 0.01rem solid #c7cdda;
  border-left: 0;
  padding: 0.44rem 0.3rem 0.26rem 0.25rem;
}

.code .code-right .strong {
  font-size: 0.24rem;
  font-family: "PingFangSC-Medium";
  font-weight: 500;
  color: #333b6c;
  margin-bottom: 0.35rem;
}

.code .code-right p {
  font-size: 0.2rem;
  font-family: "PingFangSC-Regular";
  font-weight: 400;
  line-height: 0.32rem;
  color: rgba(101, 116, 168, 1);
  margin-bottom: 0.16rem;
}

.deploy {
  margin-bottom: 1.14rem;
}

.deploy .deploy-container {
  max-width: 12rem;
  margin: 0 auto;
}

.deploy .deploy-card {
  border-radius: 0.04rem;
  border: 0.01rem solid #c7cdda;
  padding: 0.37rem 0.97rem 0.26rem;
}

/* .deploy .deploy-card img {
  display: inline-block;
  max-width: 4.07rem;
} */

.deploy .main {
  font-size: 0.28rem;
  font-weight: 500;
  margin-bottom: 0.16rem;
  color: #3b51a2;
}

.deploy .sub {
  font-size: 0.2rem;
  font-family: "SourceHanSansCN-Normal";
  font-weight: 400;
  color: #7583b7;
  margin-bottom: 0.09rem;
}

@media screen and (max-width: 576px) {
  .our-pro .our-container {
    max-width: 6rem;
  }

  .code .code-container {
    max-width: 7rem;
  }

  .code .code-title {
    padding: 0.05rem;
  }

  .deploy .deploy-container {
    max-width: 8rem;
  }

  .deploy .deploy-card:first-child {
    margin-bottom: 0.5rem;
  }

  .banner {
    position: relative;
  }

  .banner-link {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}
</style>