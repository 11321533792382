<template>
  <div class="platform">
    <div :class="['left-nav', showLeftNav ? 'show' : '']">
      <div class="icon">
        <img :src="icon">
        <h3>平台介绍</h3>
      </div>
      <Menu
        mode="vertical"
        theme="light"
        style="width: 100%;"
        :active-name="this.enArea"
        @on-select="changeArea"
      >
        <MenuItem name="platform_desc">
        <Icon
          type="ios-paper"
          size="23"
        />
        平台概述
        </MenuItem>

        <!-- <Submenu name="basic">
          <template slot="title">
            <Icon
              type="ios-paper"
              size="23"
            />
            基础知识
          </template>
          <MenuItem name="basic_concept">基本概念</MenuItem>
          <MenuItem name="token">应用鉴权</MenuItem>
        </Submenu> -->

        <MenuItem name="basic_concept">
        <Icon
          type="ios-paper"
          size="23"
        />
        基本概念
        </MenuItem>
        <!-- 
        <MenuItem name="integrate">
        <Icon
          type="ios-paper"
          size="23"
        />
        集成指南
        </MenuItem> -->

        <!-- <MenuItem name="prepare">
        <Icon
          type="ios-paper"
          size="23"
        />
        准备工作
        </MenuItem> -->

        <MenuItem name="experience_test">
        <Icon
          type="ios-paper"
          size="23"
        />
        体验试用
        </MenuItem>
        <MenuItem name="app_integrate">
        <Icon
          type="ios-paper"
          size="23"
        />
        应用集成
        </MenuItem>
        <MenuItem name="price">
        <Icon
          type="ios-paper"
          size="23"
        />
        价格详情
        </MenuItem>
        <MenuItem name="errno">
        <Icon
          type="ios-paper"
          size="23"
        />
        错误码
        </MenuItem>
      </Menu>
    </div>
    <div class="doc-container">
      <div class="nav-bar">
        <Breadcrumb>
          <BreadcrumbItem to='/developer/platform'>平台介绍</BreadcrumbItem>
          <BreadcrumbItem>{{this.cnArea}}</BreadcrumbItem>
          <BreadcrumbItem v-if="showAlert">{{this.system}}</BreadcrumbItem>
        </Breadcrumb>
      </div>
      <div
        v-if="showTypeNav"
        class="doc-type"
      >
        <Menu
          mode="horizontal"
          theme="light"
          :active-name="this.system"
          @on-select="changeSystem"
        >
          <MenuItem
            v-for="(item, index) in docType"
            :key="index"
            :name="item.name"
          >
          {{item.name}}
          </MenuItem>
        </Menu>
      </div>
      <div class="content">
        <!-- <Alert
          v-show="showAlert"
          type="warning"
          show-icon
          closable
          class="notice"
        >本教程演示如何在{{system}}系统平台上快速集成SDK实现语音通话功能。</Alert> -->

        <div
          v-if="this.loading"
          class="load-container"
        >
          <div class="load load1"></div>
          <div class="load load2"></div>
          <div class="load"></div>
        </div>

        <vue-markdown
          v-else
          :source="docContent"
          @rendered="rendered"
        ></vue-markdown>
      </div>
    </div>
    <div class="right-nav">
      <Anchor show-ink>
        <AnchorLink
          v-for="(item, index) in anchor"
          :key="index"
          :href="item.href"
          :title="item.title"
          :scroll-offset="60"
        ></AnchorLink>
      </Anchor>
    </div>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown";
import { docUrl } from "../../service/api.js";

export default {
  components: { VueMarkdown },

  data() {
    return {
      docContent: "",
      docType: [
        { name: "windows" },
        { name: "android" },
        { name: "ios" },
        { name: "mac" },
        { name: "web" },
        { name: "weChat" }
      ],
      doc: [
        {
          icon: require("../../images/doc/doc-icon01.png"),
          title: "音频通信",
          production: "audio"
        },
        {
          icon: require("../../images/doc/doc-icon02.png"),
          title: "视频通信",
          production: "video"
        },
        {
          icon: require("../../images/doc/doc-icon03.png"),
          title: "屏幕共享",
          production: "screen_share"
        },
        {
          icon: require("../../images/doc/doc-icon04.png"),
          title: "信令通道",
          production: "tmsg"
        },
        {
          icon: require("../../images/doc/doc-icon05.png"),
          title: "在线",
          production: "online"
        }
      ],
      anchor: [],
      loading: true,
      routerQuery: {},
      icon: require("../../images/doc/doc-icon02.png"),
      title: "",
      production: "platform",
      enArea: "platform_desc",
      system: "windows",
      showTypeNav: true,
      showDocumentNav: true,
      showAlert: true
    };
  },

  computed: {
    cnArea() {
      switch (this.enArea) {
        case "platform_desc":
          return "平台概述";
        case "basic_concept":
          return "基本概念";
        // case "integrate":
        //   return "集成指南";
        //   break;
        // case "prepare":
        //   return "准备工作";
        //   break;
        case "experience_test":
          return "体验试用";
        case "app_integrate":
          return "应用集成";
        case "price":
          return "价格详情";
        case "errno":
          return "错误码";
        default:
          return "";
      }
    },

    showLeftNav() {
      return this.$store.state.showLeftNav;
    }
  },

  mounted() {
    // console.log('this.$route' + this.$route);
    this.$route.query.production
      ? (this.showDocumentNav = false)
      : (this.showDocumentNav = true);

    this.$route.params.area
      ? (this.enArea = this.$route.params.area)
      : (this.enArea = "platform_desc");

    this.switchNav();

    this.toWhere();
  },

  beforeRouteUpdate(to, from, next) {
    next();
  },

  methods: {
    rendered() {
      this.$nextTick(() => {
        this.anchor = [];
        let h2Arr = document.querySelectorAll("h2");

        h2Arr.forEach(item => {
          this.attachANode(item);
        });
      });
    },

    // gotoDocDetail(item) {
    //   console.log("item", item);
    //   this.$router.push({ query: { production: item.production } });
    //   this.icon = item.icon;
    //   this.title = item.title;
    //   this.production = item.production;
    //   this.showDocumentNav = false;
    //   this.getAndDealDoc(this.production + "/platform_desc.md");
    // },

    toWhere() {
      if (
        this.enArea == "platform_desc" ||
        this.enArea == "basic_concept" ||
        this.enArea == "experience_test" ||
        this.enArea == "app_integrate" ||
        this.enArea == "price" ||
        this.enArea == "errno"
      ) {
        this.getAndDealDoc(this.production + "/" + this.enArea + ".md");
      } else {
        this.getAndDealDoc(
          this.production + "/" + this.enArea + "_" + this.system + ".md"
        );
      }
    },

    getAndDealDoc(url) {
      this.loading = true;

      fetch(docUrl + url, {method: "GET", mode: "cors"}).then(res => {
        return res.text();
      }).then(data => {
        if (!data) {
          this.$Message.warning({
            content: "暂无此项内容"
          });
          this.docContent = "";
          this.loading = false;
        } else {
          this.docContent = data;
          this.loading = false;
        }
      });
    },

    changeSystem(name) {
      this.system = name;

      this.getAndDealDoc(
        this.production + "/" + this.enArea + "_" + this.system + ".md"
      );
    },

    changeArea(name) {
      this.enArea = name;

      this.switchNav();

      this.toWhere();
    },

    switchNav() {
      if (
        this.enArea == "platform_desc" ||
        this.enArea == "basic_concept" ||
        this.enArea == "experience_test" ||
        this.enArea == "app_integrate" ||
        this.enArea == "price" ||
        this.enArea == "errno"
      ) {
        this.showTypeNav = false;
        this.showAlert = false;
      } else {
        this.showTypeNav = true;
        this.showAlert = true;
      }
    },

    /**
     * 在node节点前添加一个a标签
     */
    attachANode(node) {
      let id = node.innerText.replace(/\s+/g, "_"); // 替换空格
      node.setAttribute("id", id);
      let a = document.createElement("a");
      let href = "#" + node.innerText.replace(/\s+/g, "_");
      a.setAttribute("href", href);
      node.insertAdjacentElement("beforebegin", a);

      this.setAnchor(node.innerText, href);
    },

    /**
     * 设置锚点对象数组
     */
    setAnchor(title, href) {
      let tmp = {};
      tmp.title = title;
      tmp.href = href;
      this.anchor.push(tmp);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../css/document.css";
@import "../../../node_modules/prismjs/themes/prism.css";
@import "../../css/loading.scss";

.platform {
  max-width: 13.66rem;
  margin: 0 auto;
  text-align: left;
  min-height: 93vh;
  background: #ffffff;
}

.left-nav,
.right-nav,
.doc-container {
  display: inline-block;
  vertical-align: top;
}

.left-nav {
  width: 15.2%;
  max-width: 2.07rem;
}

.left-nav .icon {
  height: 0.8rem;
  background: rgba(243, 245, 254, 1);
  text-align: center;
  line-height: 0.8rem;
}

.left-nav img {
  display: inline-block;
  max-width: 0.6rem;
}

.left-nav h3 {
  display: inline-block;
  font-family: "SourceHanSansCN-Medium";
  font-weight: 500;
  font-size: 0.2rem;
  color: #3b51a2;
}

.right-nav {
  width: 15%;
  position: fixed;
  max-width: 2rem;
}

.doc-container {
  width: 69.8%;
  max-width: 10.04rem;
  min-height: 93vh;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.nav-bar,
.doc-type {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.nav-bar {
  height: 0.4rem;
  line-height: 0.4rem;
}

.ivu-menu-horizontal,
.doc-type {
  height: 0.46rem;
  line-height: 0.46rem;
  /* margin-bottom: 0.16rem; */
  z-index: 0;
}

.content {
  padding: 0 0.2rem;
}

.ivu-menu-horizontal,
.ivu-breadcrumb span:first-child {
  margin-left: 0.2rem;
}

.ivu-menu-vertical .ivu-menu-item {
  font-family: "PingFangSC-Medium";
  font-weight: 500;
}

.ivu-anchor-link {
  font-size: 0.14rem;
}

.content {
  margin-bottom: 0.2rem;
}

.notice {
  display: inline-block;
  min-width: 50%;
  margin-top: 0.16rem;
}

@media screen and (max-width: 576px) {
  .right-nav {
    display: none;
    width: 0;
  }

  .doc-container {
    width: 100%;
    margin-top: 0.1rem;
    border: 0;
  }

  .left-nav {
    position: fixed;
    width: 3rem;
    max-width: 3rem;
    border-right: 0.01rem solid rgba(233, 233, 233, 1);
    background: #ffffff;
    top: 0.56rem;
    left: -8rem;
    bottom: 0;
    transition: all 0.3s ease-in;
    z-index: 90;
  }

  .left-nav.show {
    display: block;
    left: 0;
  }

  .ivu-breadcrumb {
    font-size: 0.14rem;
  }
}
</style>