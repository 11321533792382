<template>
  <!-- eslint-disable -->
  <div class="case">
    <div class="modal"></div>
    <div class="title">客户案例</div>
    <Row
      type="flex"
      justify="start"
      style="width: 18.43rem;"
    >
      <Col
        :lg="2"
        :xs="4"
        v-for="(item, index) in customerCase"
        :key="index"
        class="icon"
      >
      <img
        :src="item.iconUrl"
        alt="icon"
      >
      </Col>
    </Row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      customerCase: [
        {
          iconUrl: require("../images/case/icon (1).png")
        },
        {
          iconUrl: require("../images/case/icon (2).png")
        },
        {
          iconUrl: require("../images/case/icon (3).png")
        },
        {
          iconUrl: require("../images/case/icon (4).png")
        },
        {
          iconUrl: require("../images/case/icon (5).png")
        },
        {
          iconUrl: require("../images/case/icon (6).png")
        },
        {
          iconUrl: require("../images/case/icon (7).png")
        },
        {
          iconUrl: require("../images/case/icon (8).png")
        },
        {
          iconUrl: require("../images/case/icon (9).png")
        },
        {
          iconUrl: require("../images/case/icon (10).png")
        },
        {
          iconUrl: require("../images/case/icon (11).png")
        },
        {
          iconUrl: require("../images/case/icon (12).png")
        },
        {
          iconUrl: require("../images/case/icon (13).png")
        },
        {
          iconUrl: require("../images/case/icon (14).png")
        },
        {
          iconUrl: require("../images/case/icon (15).png")
        },
        {
          iconUrl: require("../images/case/icon (16).png")
        },
        {
          iconUrl: require("../images/case/icon (17).png")
        },
        {
          iconUrl: require("../images/case/icon (18).png")
        },
        {
          iconUrl: require("../images/case/icon (19).png")
        },
        {
          iconUrl: require("../images/case/icon (20).png")
        },
        {
          iconUrl: require("../images/case/icon (21).png")
        },
        {
          iconUrl: require("../images/case/icon (22).png")
        },
        {
          iconUrl: require("../images/case/icon (23).png")
        },
        {
          iconUrl: require("../images/case/icon (24).png")
        },
        {
          iconUrl: require("../images/case/icon (25).png")
        },
        {
          iconUrl: require("../images/case/icon (26).png")
        },
        {
          iconUrl: require("../images/case/icon (27).png")
        },
        {
          iconUrl: require("../images/case/icon (28).png")
        },
        {
          iconUrl: require("../images/case/icon (29).png")
        },
        {
          iconUrl: require("../images/case/icon (30).png")
        },
        {
          iconUrl: require("../images/case/icon (31).png")
        },
        {
          iconUrl: require("../images/case/icon (32).png")
        },
        {
          iconUrl: require("../images/case/icon (33).png")
        },
        {
          iconUrl: require("../images/case/icon (34).png")
        },
        {
          iconUrl: require("../images/case/icon (35).png")
        },
        {
          iconUrl: require("../images/case/icon (36).png")
        },
        {
          iconUrl: require("../images/case/icon (37).png")
        },
        {
          iconUrl: require("../images/case/icon (38).png")
        },
        {
          iconUrl: require("../images/case/icon (39).png")
        },
        {
          iconUrl: require("../images/case/icon (40).png")
        },
        {
          iconUrl: require("../images/case/icon (41).png")
        },
        {
          iconUrl: require("../images/case/icon (42).png")
        },
        {
          iconUrl: require("../images/case/icon (43).png")
        },
        {
          iconUrl: require("../images/case/icon (44).png")
        },
        {
          iconUrl: require("../images/case/icon (45).png")
        },
        {
          iconUrl: require("../images/case/icon (46).png")
        },
        {
          iconUrl: require("../images/case/icon (47).png")
        },
        {
          iconUrl: require("../images/case/icon (48).png")
        },
        {
          iconUrl: require("../images/case/icon (49).png")
        },
        {
          iconUrl: require("../images/case/icon (50).png")
        },
        {
          iconUrl: require("../images/case/icon (51).png")
        },
        {
          iconUrl: require("../images/case/icon (52).png")
        },
        {
          iconUrl: require("../images/case/icon (53).png")
        },
        {
          iconUrl: require("../images/case/icon (54).png")
        },
        {
          iconUrl: require("../images/case/icon (55).png")
        },
        {
          iconUrl: require("../images/case/icon (56).png")
        },
        {
          iconUrl: require("../images/case/icon (57).png")
        },
        {
          iconUrl: require("../images/case/icon (58).png")
        },
        {
          iconUrl: require("../images/case/icon (59).png")
        },
        {
          iconUrl: require("../images/case/icon (60).png")
        },
        {
          iconUrl: require("../images/case/icon (61).png")
        },
        {
          iconUrl: require("../images/case/icon (62).png")
        },
        {
          iconUrl: require("../images/case/icon (63).png")
        },
        {
          iconUrl: require("../images/case/icon (64).png")
        },
        {
          iconUrl: require("../images/case/icon (65).png")
        },
        {
          iconUrl: require("../images/case/icon (66).png")
        },
        {
          iconUrl: require("../images/case/icon (67).png")
        },
        {
          iconUrl: require("../images/case/icon (68).png")
        },
        {
          iconUrl: require("../images/case/icon (69).png")
        },
        {
          iconUrl: require("../images/case/icon (70).png")
        },
        {
          iconUrl: require("../images/case/icon (71).png")
        },
        {
          iconUrl: require("../images/case/icon (72).png")
        },
        {
          iconUrl: require("../images/case/icon (73).png")
        },
        {
          iconUrl: require("../images/case/icon (74).png")
        },
        {
          iconUrl: require("../images/case/icon (75).png")
        },
        {
          iconUrl: require("../images/case/icon (76).png")
        },
        {
          iconUrl: require("../images/case/icon (77).png")
        },
        {
          iconUrl: require("../images/case/icon (78).png")
        },
        {
          iconUrl: require("../images/case/icon (79).png")
        },
        {
          iconUrl: require("../images/case/icon (80).png")
        }
      ]
    };
  }
};
</script>

<style scoped>
.case {
  position: relative;
  width: 100%;
  padding: 0.44rem 0.3rem;
  background: #222537;
  overflow: hidden;
}

.case .modal {
  z-index: 9;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    270deg,
    rgba(30, 37, 69, 1) 0%,
    rgba(51, 56, 78, 0) 50%,
    rgba(30, 37, 69, 1) 100%
  );
}

.case .title {
  color: #ffffff;
  font-size: 0.36rem;
  font-weight: 500;
  margin-bottom: 0.36rem;
}

.case .icon {
  display: inline-block;
  max-width: 0.7rem;
  margin-right: 0.2rem;
  margin-bottom: 0.1rem;
}

@media screen and (max-width: 576px) {
  /* .case {
    height: 6.5rem;
    overflow: hidden;
  }

  .case .icon {
    max-width: 1.2rem;
  } */
}
</style>