<template>
  <!-- eslint-disable -->
  <div class="home">
    <!-- 轮播 -->
    <div class="banner">
      <Carousel
        arrow="never"
        dots="none"
        loop
      >
        <CarouselItem
          v-for="(item, index) in banner"
          :key="index"
        >
          <img :src="isMobile ? item.mobileImgUrl : item.imgUrl">
        </CarouselItem>
      </Carousel>
      <div
        v-if="!isMobile"
        @click="goAnywhere"
        class="banner-btn"
      ></div>
      <a
        v-if="isMobile"
        href="http://customer.paas.hst.com/login"
        class="banner-link"
      ></a>
    </div>
    <!-- 特点 -->
    <div class="sub-banner">
      <div class="lg-container">
        <Row
          type="flex"
          justify="space-between"
        >
          <Col
            span="5"
            class="sub-card"
            v-for="(item, index) in subBanner"
            :key="index"
          >
          <div class="thumbnail">
            <img
              :src="item.imgUrl"
              alt="icon"
            >
          </div>
          <div class="description">
            <div class="desc-main">{{item.title}}</div>
            <div class="sub">{{item.description}}</div>
          </div>
          </Col>
        </Row>
      </div>
    </div>
    <!-- 场景 -->
    <div class="scene lg-container">
      <div class="title">几行代码，轻松集成音视频能力</div>
      <div class="scene-card-container">
        <Row
          type="flex"
          justify="space-around"
        >
          <Col
            :lg="5"
            :xs="10"
            class="scene-card"
            v-for="(item, index) in scene"
            :key="index"
          >
          <div class="thumbnail">
            <img
              :src="item.imgUrl"
              alt="icon"
            >
          </div>
          <div class="description">
            <div class="main">{{item.title}}</div>
            <div class="sub">{{item.description}}</div>
          </div>
          </Col>
        </Row>
      </div>
      <a
        href="http://customer.paas.hst.com/monitor_main/panel"
        target="_blank"
      >
        <div class="btn">免费体验
        </div>
      </a>
    </div>
    <!-- 能力 -->
    <div style="background: rgb(246,247,252); padding: 0.8rem 0; margin-bottom: 0.8rem;">
      <div class="service lg-container">
        <div class="title">丰富的通信服务能力</div>
        <div class="sub-title">源于视频会议，满足更多业务需求</div>
        <div class="service-card-container">
          <Row
            type="flex"
            justify="space-around"
          >
            <Col
              span="5"
              class="service-card"
              v-for="(item, index) in service"
              :key="index"
            >
            <div class="thumbnail">
              <img
                :src="item.imgUrl"
                alt="icon"
              >
            </div>
            <div class="description">
              <div class="main">{{item.title}}</div>
            </div>
            </Col>
          </Row>
        </div>
        <a
          href="http://customer.paas.hst.com/monitor_main/panel"
          target="_blank"
        >
          <div class="btn">免费体验</div>
        </a>
      </div>
    </div>
    <!-- 平台 -->
    <div class="platform lg-container">
      <div class="title">领先的流式分发平台</div>
      <div class="sub-title">支持超大并发，正服务于全球数十万视频会议用户</div>
      <div class="tab-platform">
        <Row
          type="flex"
          justify="space-around"
          class="tab-container"
        >
          <Col
            :lg="5"
            :xs="10"
            :class="['tab-title', curPlatformItem==index ? 'active' : '']"
            v-for="(item, index) in platform"
            :key="index"
          >
          <span @click="curPlatformItem=index">
            {{item.title}}
          </span>
          </Col>
        </Row>
        <div class="tab-content">
          <div
            v-for="(item, index) in platform"
            :key="index"
            v-show="curPlatformItem==index"
          >
            <img
              :src="item.imgUrl"
              :alt="item.title"
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 技术支持 -->
    <div style="background: rgb(246,247,252); padding: 0.4rem 0;">
      <div class="support lg-container">
        <div class="title">即时响应的技术支持</div>
        <div class="sub-title">助力开发加速，保障售后无忧</div>
        <div class="tab-support">
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            class="tab-container"
          >
            <Col
              :lg="8"
              :xs="0"
            >
            <div
              :class="['tab-title', curSupportItem==index ? 'active' : '']"
              v-for="(item, index) in support"
              :key="index"
              @click="curSupportItem=index"
            >{{item.title}}</div>
            </Col>
            <Col
              :lg="15"
              :xs="24"
            >
            <div
              v-for="(item, index) in support"
              :key="index"
              v-show="curSupportItem==index"
            >
              <img
                :src="item.imgUrl"
                :alt="item.title"
              >
            </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
    <!-- 案例 -->
    <CustomerCase />
  </div>
</template>

<script>
import CustomerCase from "../components/CustomerCase";

export default {
  components: { CustomerCase },

  data() {
    return {
      banner: [
        {
          imgUrl: require("../images/home/home-banner01.png"),
          mobileImgUrl: require("../images/home/home-banner02.png")
        }
      ],
      subBanner: [
        {
          imgUrl: require("../images/home/home-icon01.png"),
          title: "高可用",
          description: "99.99%可用性"
        },
        {
          imgUrl: require("../images/home/home-icon02.png"),
          title: "高性能",
          description: "单分组10000并发"
        },
        {
          imgUrl: require("../images/home/home-icon03.png"),
          title: "低时延",
          description: "端到端200ms"
        },
        {
          imgUrl: require("../images/home/home-icon04.png"),
          title: "全球通",
          description: "服务全球用户"
        }
      ],
      scene: [
        {
          imgUrl: require("../images/home/home-icon05.png"),
          title: "语音通话",
          description:
            "可以实现一对一、多对多语音通话，10000人超大并发，48K高保真音质，弱网环境下仍能保持声音实时清晰、流畅"
        },
        {
          imgUrl: require("../images/home/home-icon06.png"),
          title: "视频通话",
          description:
            "可以实现一对一、多对多视频通话，10000人超大并发，1080P超清画质，弱网环境下仍能保持视频实时清晰、流畅"
        },
        {
          imgUrl: require("../images/home/home-icon07.png"),
          title: "视频会议",
          description:
            "基于平台提供丰富的音视频通信服务，可以轻松实现一个具备高质量音视频互动和数据共享能力的视频会议应用"
        },
        {
          imgUrl: require("../images/home/home-icon08.png"),
          title: "更多场景",
          description:
            "除此之外，还可以应用在远程教育、远程培训、远程医疗、游戏、社交、直播等更多场景"
        }
      ],
      service: [
        {
          imgUrl: require("../images/home/icon_audio.png"),
          title: "音频通信"
        },
        {
          imgUrl: require("../images/home/icon_video.png"),
          title: "视频通信"
        },
        {
          imgUrl: require("../images/home/icon_share_screen.png"),
          title: "屏幕共享"
        },
        {
          imgUrl: require("../images/home/icon_whiteboard.png"),
          title: "电子白板"
        },
        {
          imgUrl: require("../images/home/icon_share_doc.png"),
          title: "文档共享"
        },
        {
          imgUrl: require("../images/home/icon_live.png"),
          title: "互动直播"
        },
        {
          imgUrl: require("../images/home/icon_chat.png"),
          title: "文字聊天"
        },
        {
          imgUrl: require("../images/home/icon_channel.png"),
          title: "信令通道"
        }
      ],
      platform: [
        {
          title: "智能网络适应",
          imgUrl: require("../images/home/home-pic01.png")
        },
        {
          title: "自动弹性扩容",
          imgUrl: require("../images/home/home-pic01.png")
        },
        {
          title: "灵活动态负载",
          imgUrl: require("../images/home/home-pic01.png")
        },
        {
          title: "智能路由转发",
          imgUrl: require("../images/home/home-pic01.png")
        }
      ],
      support: [
        {
          title: "高效专业的服务团队",
          imgUrl: require("../images/home/home-pic02.png")
        },
        {
          title: "7*24小时不间断服务",
          imgUrl: require("../images/home/home-pic02.png")
        },
        {
          title: "极速响应、快速解答",
          imgUrl: require("../images/home/home-pic02.png")
        },
        {
          title: "VIP一对一专人支持",
          imgUrl: require("../images/home/home-pic02.png")
        }
      ],
      curPlatformItem: 0,
      curSupportItem: 0
    };
  },

  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  },

  mounted() {},

  methods: {
    goAnywhere() {
      window.open("http://customer.paas.hst.com/monitor_main/panel");
    }
  }
};
</script>

<style scoped>
.title {
  font-size: 0.32rem;
  font-weight: 500;
  color: #575a71;
  margin-bottom: 0.2rem;
}

.sub-title {
  font-size: 0.24rem;
  font-family: "SourceHanSansCN-Regular";
  font-weight: 400;
  color: #575a71;
  opacity: 0.8;
  margin-bottom: 0.49rem;
}

.btn {
  margin: 0.35rem auto;
  margin-bottom: 0;
  cursor: pointer;
  max-width: 1.44rem;
  height: 0.42rem;
  line-height: 0.42rem;
  background: #ff9f29;
  border-radius: 0.21rem;
  color: #ffffff;
  font-size: 0.18rem;
  font-weight: 500;
}

.banner {
  position: relative;
  width: 100%;
}

.banner-btn {
  position: absolute;
  cursor: pointer;
  background-image: url("../images/home/home-btn01.svg");
  background-size: contain;
  width: 1.61rem;
  height: 0.43rem;
  left: 48%;
  top: 64%;
  transition: all 0.3s;
}

.banner-btn:hover {
  background-image: url("../images/home/home-btn02.svg");
}

.banner-btn:active {
  background-image: url("../images/home/home-btn03.svg");
}

.sub-banner {
  min-width: 12rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  margin-bottom: 0.8rem;
  background: #f5f8ff;
  border-bottom: 0.01rem solid #cdd4e5;
}

.sub-banner .sub-card {
  position: relative;
  padding: 0.1rem 0;
  /* background: #f7f7f9; */
  /* box-shadow: 0px 8px 16px 0px rgba(170, 184, 255, 0.3); */
  border-radius: 8px;
}

.sub-banner .sub-card::after {
  position: absolute;
  display: block;
  content: " ";
  width: 0.02rem;
  height: 1.08rem;
  background: linear-gradient(
    180deg,
    rgba(245, 248, 255, 1) 0%,
    rgba(205, 212, 229, 1) 50%,
    rgba(245, 248, 255, 1) 100%
  );
  right: -0.41rem;
  top: -0.1rem;
}

.sub-banner .sub-card:last-child:after {
  display: none;
}

.sub-banner .thumbnail {
  display: inline-block;
}

.sub-banner .thumbnail img {
  max-width: 0.7rem;
}

.sub-banner .description {
  display: inline-block;
  vertical-align: top;
  padding-top: 0.1rem;
  margin-left: 0.2rem;
  color: #3a4bb9;
}

.sub-banner .description .main {
  font-size: 0.24rem;
  font-weight: 500;
  margin-bottom: 0.1rem;
}

.sub-banner .description .desc-main {
  font-size: 0.24rem;
  font-weight: 500;
  margin-bottom: 0.1rem;
  text-align: left;
}

.sub-banner .description .sub {
  font-size: 0.18rem;
  font-weight: 400;
  font-family: "SourceHanSansCN-Regular";
}

.scene {
  margin-bottom: 0.8rem;
}

.scene .title {
  margin-bottom: 0.71rem;
}

.scene .thumbnail img {
  display: inline-block;
  max-width: 1.4rem;
}

.scene .description .main {
  font-size: 0.24rem;
  font-weight: 500;
  color: #3b51a2;
  line-height: 0.42rem;
  margin-bottom: 0.03rem;
}

.scene .description .sub {
  padding: 0 0.25rem;
  font-size: 0.14rem;
  font-family: "SourceHanSansCN-Normal";
  font-weight: 400;
  color: #989cbd;
  line-height: 0.24rem;
}

.service {
  /* margin-bottom: 0.57rem; */
}

.service .service-card-container {
  max-width: 8rem;
  margin: 0 auto;
}

.service .sub-title {
  margin-bottom: 0.7rem;
}

.service .service-card {
  margin-bottom: 0.4rem;
}

.service .thumbnail {
  margin-bottom: 0.11rem;
}

.service .thumbnail img {
  display: inline-block;
  max-width: 1rem;
}

.service .description .main {
  font-size: 0.22rem;
  font-weight: 500;
  color: #3b51a2;
  line-height: 0.36rem;
}

.service .btn {
  /* margin-top: 0; */
}

.platform {
  margin-bottom: 0.8rem;
}

.platform .tab-container {
  max-width: 8rem;
  margin: 0 auto;
}

.platform .sub-title {
  margin-bottom: 0.8rem;
}

.platform .tab-title {
  display: inline-block;
  font-size: 0.2rem;
  font-weight: 500;
  color: #3b51a2;
  border: 0.01rem solid #3b51a2;
  border-radius: 0.08rem 0.08rem 0.08rem 0rem;
  padding: 0.1rem 0;
  margin-bottom: 0.4rem;
  transition: all 300ms;
}

/* .platform .tab-title.active {
  color: #3b51a2;
  border: 0.01rem solid #3b51a2;
} */

.support {
  /* margin-bottom: 0.82rem; */
}

.support .sub-title {
  margin-bottom: 0.6rem;
}

.support .tab-container {
  max-width: 8rem;
  margin: 0 auto;
}

.support .tab-title {
  /* cursor: pointer; */
  max-width: 3rem;
  height: 0.7rem;
  line-height: 0.7rem;
  font-family: "SourceHanSansCN-Regular";
  font-size: 0.24rem;
  font-weight: 400;
  color: #3b51a2;
  background: #f3f5fe;
  margin-bottom: 0.12rem;
  border-radius: 0.04rem;
  transition: all 300ms;
}

/* .support .tab-title.active {
  background: #73a2f7;
  color: #ffffff;
} */

@media screen and (max-width: 576px) {
  .sub-banner {
    display: none;
  }

  .scene .scene-card {
    margin-bottom: 0.34rem;
  }

  .platform .tab-container {
    max-width: 5rem;
  }

  .support .tab-container {
    max-width: 6rem;
  }

  .banner {
    position: relative;
    margin-bottom: 0.8rem;
  }

  .banner-link {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .banner-btn {
    right: 30%;
    bottom: 17%;
  }

  .btn {
    font-size: .16rem;
  }
}
</style>