<template>
  <div class="online">
    <!-- banner -->
    <div class="banner">
      <img
        :src="isMobile ? banner.mobileImgUrl : banner.imgUrl"
        alt="audio"
      >
      <div
        v-if="!isMobile"
        @click="goAnywhere"
        class="banner-btn1"
      >免费试用</div>
      <div
        v-if="!isMobile"
        class="banner-btn2"
      >
        <router-link :to="{path: '/developer/code'}">立即体验</router-link>
      </div>
      <a
        v-if="isMobile"
        href="http://customer.paas.hst.com/login"
        class="banner-link"
      ></a>
    </div>
    <!-- 特性 -->
    <div class="feature">
      <div class="title">功能特性</div>
      <Row
        type="flex"
        justify="space-around"
        class="feature-container"
      >
        <Col
          :lg="5"
          :xs="11"
          class="feature-card"
          v-for="(item, index) in feature"
          :key="index"
        >
        <div class="thumbnail">
          <img
            :src="item.imgUrl"
            :alt="item.title"
          >
        </div>
        <div class="description">
          <div class="main">{{item.title}}</div>
          <div class="sub">{{item.description}}</div>
        </div>
        </Col>
      </Row>
    </div>
    <!-- 优势 -->
    <div class="advantage">
      <div class="title">产品优势</div>
      <Row
        type="flex"
        justify="space-around"
        class="advantage-container"
      >
        <Col
          span="10"
          class="advantage-card"
          v-for="(item, index) in advantage"
          :key="index"
        >
        <div class="thumbnail">
          <img
            :src="item.imgUrl"
            :alt="item.title"
          >
        </div>
        <div class="description">
          <div class="main">{{item.title}}</div>
          <div class="sub">{{item.description}}</div>
        </div>
        </Col>
      </Row>
    </div>
    <!-- 场景 -->
    <div class="scene">
      <div class="title">应用场景</div>
      <Row
        type="flex"
        justify="space-around"
        class="scene-container"
      >
        <Col
          span="11"
          class="scene-card"
          v-for="(item, index) in scene"
          :key="index"
        >
        <div class="thumbnail">
          <img
            :src="item.imgUrl"
            :alt="item.title"
          >
        </div>
        <div class="description">
          <div class="main">{{item.title}}</div>
          <div class="sub">{{item.description}}</div>
        </div>
        </Col>
      </Row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      banner: {
        imgUrl: require("../../images/online/online-banner01.png"),
        mobileImgUrl: require("../../images/online/online-banner02.png")
      },
      feature: [
        {
          imgUrl: require("../../images/online/online-icon01.png"),
          title: "在线状态管理",
          description: "获取所有用户的在线状态"
        },
        {
          imgUrl: require("../../images/online/online-icon02.png"),
          title: "通讯录",
          description: "支持企业通讯录"
        },
        {
          imgUrl: require("../../images/online/online-icon03.png"),
          title: "多终端在线",
          description: "单用户多终端同时在线"
        },
        {
          imgUrl: require("../../images/online/online-icon04.png"),
          title: "在线呼叫",
          description: "呼叫在线用户加入分组"
        }
      ],
      advantage: [
        {
          imgUrl: require("../../images/online/online-icon05.png"),
          title: "简单",
          description: "一个接口实现呼叫功能"
        },
        {
          imgUrl: require("../../images/online/online-icon06.png"),
          title: "灵活",
          description: "更多自定义属性和规则"
        }
      ],
      scene: [
        {
          imgUrl: require("../../images/online/online-icon07.png"),
          title: "在线呼叫",
          description: "实现像微信一样的呼叫功能"
        },
        {
          imgUrl: require("../../images/online/online-icon08.png"),
          title: "在线管理",
          description: "基于在线状态做业务控制"
        }
      ]
    };
  },

  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  },

  methods: {
    goAnywhere() {
      window.open("http://customer.paas.hst.com/register?userFrom=PaaS%E5%AE%98%E7%BD%91");
    }
  }
};
</script>

<style scoped>
.banner {
  position: relative;
  margin-bottom: 0.8rem;
}

.banner-btn1 {
  position: absolute;
  cursor: pointer;
  width: 1.44rem;
  height: 0.42rem;
  border-radius: 0.21rem;
  background: #ff9f29;
  color: #ffffff;
  font-size: 0.18rem;
  font-weight: 500;
  line-height: 0.42rem;
  top: 67%;
  left: 19%;
  transition: all 0.3s;
}

.banner-btn1:hover {
  background: #ff8d00;
}

.banner-btn1:active {
  background: #d17300;
}

.banner-btn2 {
  position: absolute;
  cursor: pointer;
  width: 1.44rem;
  height: 0.42rem;
  border: 0.01rem solid #ff9f29;
  border-radius: 0.21rem;
  font-size: 0.18rem;
  font-weight: 500;
  line-height: 0.42rem;
  top: 67%;
  left: 28%;
  transition: all 0.3s;
}

.banner-btn2 a {
  color: #ffffff;
}

.banner-btn2:hover {
  border-color: #ff8d00;
}

.banner-btn2:hover a {
  color: #ff8d00;
}

.banner-btn2:active {
  border-color: #d17300;
}

.banner-btn2:active a {
  color: #d17300;
}

.title {
  font-size: 0.32rem;
  font-weight: 500;
  color: #575a71;
  margin-bottom: 0.45rem;
}

.title::after {
  content: "";
  display: block;
  width: 0.4rem;
  height: 0.04rem;
  background: #495eeb;
  margin: 0.1rem auto;
  margin-top: 0.15rem;
}

.feature {
  margin-bottom: 1rem;
}

.feature .feature-container {
  max-width: 13rem;
  margin: 0 auto;
  margin-top: 0.6rem;
}

.feature .feature-card {
  border-radius: 0.24rem;
  border: 0.01rem solid rgba(199, 205, 218, 1);
  padding: 0.32rem 0 0.65rem;
  margin-bottom: 0.2rem;
}

.feature .thumbnail img {
  max-width: 1rem;
  display: inline-block;
  margin-bottom: 0.34rem;
}

.feature .main {
  font-size: 0.24rem;
  font-weight: 500;
  color: #495eeb;
  margin-bottom: 0.26rem;
}

.feature .sub {
  font-size: 0.18rem;
  font-family: "SourceHanSansCN-Regular";
  font-weight: 400;
  color: #929292;
}

.advantage {
  background: #f5f8ff;
  margin-bottom: 0.8rem;
  padding: 0.8rem 0 1.05rem;
}

.advantage .advantage-card {
  position: relative;
}

.advantage .advantage-card::after {
  position: absolute;
  display: block;
  content: " ";
  background-image: url("../../images/audio/audio-icon08.png");
  background-size: contain;
  width: 0.34rem;
  height: 0.34rem;
  right: -0.39rem;
  top: 0.38rem;
}

.advantage .advantage-card:last-child::after {
  display: none;
}

.advantage .advantage-container {
  max-width: 5rem;
  margin: 0 auto;
  margin-top: 0.7rem;
}

.advantage .thumbnail img {
  max-width: 1rem;
  display: inline-block;
  margin-bottom: 0.47rem;
}

.advantage .main {
  font-size: 0.24rem;
  font-weight: 500;
  color: #3b51a2;
  margin-bottom: 0.16rem;
}

.advantage .sub {
  font-size: 0.18rem;
  font-family: "SourceHanSansCN-Normal";
  font-weight: 400;
  color: #989cbd;
}

.scene {
  margin-bottom: 0.8rem;
}

.scene .scene-container {
  max-width: 8rem;
  margin: 0 auto;
  margin-top: 0.8rem;
}

.scene .thumbnail img {
  max-width: 1.6rem;
  display: inline-block;
  margin-bottom: 0.49rem;
}

.scene .main {
  font-size: 0.24rem;
  font-weight: 500;
  color: #3b51a2;
  margin-bottom: 0.11rem;
}

.scene .sub {
  font-size: 0.18rem;
  font-family: "SourceHanSansCN-Normal";
  font-weight: 400;
  color: #989cbd;
}

@media screen and (max-width: 576px) {
  .feature .feature-container {
    max-width: 7rem;
  }

  .banner {
    position: relative;
  }

  .banner-link {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}
</style>