import Home from '../views/Home.vue'
import Price from '../views/Price.vue'
import Developer from '../views/Developer.vue'

import Production from '../views/production/Production.vue'
import Audio from '../views/production/Audio.vue'
import Video from '../views/production/Video.vue'
import Share from '../views/production/Share.vue'
import Rec from '../views/production/Rec.vue'
import Online from '../views/production/Online.vue'
import Signal from '../views/production/Signal.vue'
import Private from '../views/production/Private.vue'

import Platform from '../views/developer/Platform.vue'
import Foundation from '../views/developer/Foundation.vue'
import Document from '../views/developer/Document.vue'
import Sdk from '../views/developer/Sdk.vue'
import Demo from '../views/developer/Demo.vue'
import Code from '../views/developer/Code.vue'

export default [{
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/price',
    name: 'price',
    component: Price
  },
  {
    path: '/production',
    name: 'production',
    component: Production,
  },
  {
    path: '/production/audio',
    name: 'audio',
    component: Audio
  }, 
  {
    path: '/production/video',
    name: 'video',
    component: Video
  }, 
  {
    path: '/production/share',
    name: 'share',
    component: Share
  }, 
  {
    path: '/production/rec',
    name: 'rec',
    component: Rec
  }, 
  {
    path: '/production/online',
    name: 'online',
    component: Online
  }, 
  {
    path: '/production/signal',
    name: 'signal',
    component: Signal
  }, 
  {
    path: '/production/private',
    name: 'private',
    component: Private
  },
  {
    path: '/developer',
    name: 'developer',
    component: Developer,
    children: [
      {
        path: 'platform',
        name: 'platform',
        component: Platform
      },
      {
        path: 'foundation',
        name: 'foundation',
        component: Foundation
      },
      {
        path: 'document',
        name: 'document',
        component: Document
      },
      {
        path: 'sdk',
        name: 'sdk',
        component: Sdk
      },
      {
        path: 'demo',
        name: 'demo',
        component: Demo
      },
      {
        path: 'code',
        name: 'code',
        component: Code
      }
    ]
  }
]