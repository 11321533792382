var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.showDocumentNav)?_c('div',{staticClass:"doc"},[_c('div',{class:['left-nav', _vm.showLeftNav ? 'show' : '']},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":_vm.icon}}),_c('h3',[_vm._v(_vm._s(this.title))])]),_c('Menu',{staticStyle:{"width":"100%"},attrs:{"mode":"vertical","theme":"light","active-name":this.enArea},on:{"on-select":_vm.changeArea}},[_c('MenuItem',{attrs:{"name":"product_summary"}},[_c('Icon',{attrs:{"type":"ios-paper","size":"23"}}),_vm._v(" 产品介绍 ")],1),_c('MenuItem',{attrs:{"name":"prepare"}},[_c('Icon',{attrs:{"type":"ios-paper","size":"23"}}),_vm._v(" 准备工作 ")],1),_c('MenuItem',{attrs:{"name":"quick_start"}},[_c('Icon',{attrs:{"type":"ios-paper","size":"23"}}),_vm._v(" 快速开始 ")],1),_c('MenuItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.production != 'other'),expression:"production != 'other'"}],attrs:{"name":"advance_func"}},[_c('Icon',{attrs:{"type":"ios-paper","size":"23"}}),_vm._v(" 进阶教程 ")],1),_c('MenuItem',{attrs:{"name":"api"}},[_c('Icon',{attrs:{"type":"ios-paper","size":"23"}}),_vm._v(" API参考 ")],1)],1)],1),_c('div',{staticClass:"doc-container"},[_c('div',{staticClass:"nav-bar"},[_c('Breadcrumb',[_c('BreadcrumbItem',[_vm._v("产品文档")]),_c('BreadcrumbItem',[_vm._v(_vm._s(this.title))]),_c('BreadcrumbItem',[_vm._v(_vm._s(this.cnArea))]),(_vm.showAlert)?_c('BreadcrumbItem',[_vm._v(_vm._s(this.system))]):_vm._e()],1)],1),(_vm.showTypeNav)?_c('div',{staticClass:"doc-type"},[_c('Menu',{attrs:{"mode":"horizontal","theme":"light","active-name":this.system},on:{"on-select":_vm.changeSystem}},_vm._l((_vm.docType),function(item,index){return _c('MenuItem',{key:index,attrs:{"name":item.url}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1):_vm._e(),_c('div',{staticClass:"content"},[(this.enArea == 'api'
        && this.production != 'record'
        && this.production != 'live'
        && this.system != 'web'
        && this.system != 'windows'
        && this.system != 'android'
        && this.system != 'wechat'
        && this.system != 'harmony')?_c('iframe',{attrs:{"id":"api","title":"api","scrolling":"auto","marginWidth":"0","marginHeight":"0","frameBorder":"0","src":'http://paas.hst.com/api/' + this.system + '_api'}}):_vm._e(),(this.loading)?_c('div',{staticClass:"load-container"},[_c('div',{staticClass:"load load1"}),_c('div',{staticClass:"load load2"}),_c('div',{staticClass:"load"})]):_vm._e(),((this.enArea != 'api' && !this.loading) ||
        (this.enArea == 'api' && this.system == 'web') ||
        (this.enArea == 'api' && this.system == 'wechat') ||
        (this.enArea == 'api' && this.system == 'windows') ||
        (this.enArea == 'api' && this.system == 'android') ||
        (this.production == 'record') ||
        (this.production == 'live') ||
        (this.enArea == 'api' && this.system == 'harmony'))?_c('vue-markdown',{attrs:{"source":_vm.docContent},on:{"rendered":_vm.rendered}}):_vm._e()],1)]),_c('div',{staticClass:"right-nav"},[_c('Anchor',{attrs:{"show-ink":""}},_vm._l((_vm.anchor),function(item,index){return _c('AnchorLink',{key:index,attrs:{"href":item.href,"title":item.title,"scroll-offset":60}})}),1)],1)]):_c('div',{staticClass:"doc-nav"},[_c('Row',{attrs:{"type":"flex"}},_vm._l((_vm.doc),function(item,index){return _c('Col',{key:index,staticClass:"card-container",attrs:{"lg":8,"xs":12}},[_c('div',{staticClass:"doc-card"},[_c('div',{staticClass:"thumbnail"},[_c('img',{attrs:{"src":item.icon,"alt":item.title}})]),_c('div',{staticClass:"info",on:{"click":function($event){return _vm.gotoDocDetail($event, item)}}},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('ul',[_c('div',[_c('li',{attrs:{"data-area":"product_summary"}},[_vm._v("产品介绍")]),_c('li',{attrs:{"data-area":"prepare"}},[_vm._v("准备工作")]),_c('li',{attrs:{"data-area":"quick_start"}},[_vm._v("快速开始")])]),_c('div',[_c('li',{attrs:{"data-area":"advance_func"}},[_vm._v("进阶教程")]),_c('li',{attrs:{"data-area":"api"}},[_vm._v("API参考")])])])])])])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }