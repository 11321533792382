<template>
  <div class="audio">
    <!-- banner -->
    <div class="banner">
      <img
        :src="isMobile ? banner.mobileImgUrl : banner.imgUrl"
        alt="audio"
      >
      <div
        v-if="!isMobile"
        @click="goAnywhere"
        class="banner-btn1"
      >免费试用</div>
      <div
        v-if="!isMobile"
        class="banner-btn2"
      >
        <router-link :to="{path: '/developer/code'}">立即体验</router-link>
      </div>
      <a
        v-if="isMobile"
        href="http://customer.paas.hst.com/login"
        class="banner-link"
      ></a>
    </div>
    <!-- 特性 -->
    <div class="feature">
      <div class="title">功能特性</div>
      <Row
        type="flex"
        justify="space-around"
        class="feature-container"
      >
        <Col
          span="6"
          class="feature-card"
          v-for="(item, index) in feature"
          :key="index"
        >
        <div class="thumbnail">
          <img
            :src="item.imgUrl"
            :alt="item.title"
          >
        </div>
        <div class="description">
          <div class="main">{{item.title}}</div>
          <div class="sub">{{item.description}}</div>
        </div>
        </Col>
      </Row>
    </div>
    <!-- 优势 -->
    <div class="advantage">
      <div class="title">产品优势</div>
      <Row
        type="flex"
        justify="space-around"
        class="advantage-container"
      >
        <Col
          :lg="5"
          :xs="11"
          class="advantage-card"
          v-for="(item, index) in advantage"
          :key="index"
        >
        <div class="thumbnail">
          <img
            :src="item.imgUrl"
            :alt="item.title"
          >
        </div>
        <div class="description">
          <div class="main">{{item.title}}</div>
          <div class="sub">{{item.description}}</div>
        </div>
        </Col>
      </Row>
    </div>
    <!-- 场景 -->
    <div class="scene">
      <div class="title">应用场景</div>
      <Row
        type="flex"
        justify="space-around"
        class="scene-container"
      >
        <Col
          span="7"
          class="scene-card"
          v-for="(item, index) in scene"
          :key="index"
        >
        <div class="thumbnail">
          <img
            :src="item.imgUrl"
            :alt="item.title"
          >
        </div>
        <div class="description">
          <div class="main">{{item.title}}</div>
          <div class="sub">{{item.description}}</div>
        </div>
        </Col>
      </Row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      banner: {
        imgUrl: require("../../images/audio/audio-banner01.png"),
        mobileImgUrl: require("../../images/audio/audio-banner02.png")
      },
      feature: [
        {
          imgUrl: require("../../images/audio/audio-icon01.png"),
          title: "语音通话",
          description: "实时双向语音通话"
        },
        {
          imgUrl: require("../../images/audio/audio-icon02.png"),
          title: "本地录制",
          description: "客户端语音录制"
        },
        {
          imgUrl: require("../../images/audio/audio-icon03.png"),
          title: "热插拔",
          description: "音频设备热插拔通知"
        }
      ],
      advantage: [
        {
          imgUrl: require("../../images/audio/audio-icon04.png"),
          title: "高保真音质",
          description: "48K全频带和立体声"
        },
        {
          imgUrl: require("../../images/audio/audio-icon05.png"),
          title: "3A处理",
          description: "会议级语音品质保障"
        },
        {
          imgUrl: require("../../images/audio/audio-icon06.png"),
          title: "高并发",
          description: "单分组支持10000并发"
        },
        {
          imgUrl: require("../../images/audio/audio-icon07.png"),
          title: "QoS",
          description: "50%丢包不卡顿"
        }
      ],
      scene: [
        {
          imgUrl: require("../../images/audio/audio-icon09.png"),
          title: "一对一语音",
          description: "像微信一样的语音通话"
        },
        {
          imgUrl: require("../../images/audio/audio-icon10.png"),
          title: "一对多语音",
          description: "单向语音培训或直播"
        },
        {
          imgUrl: require("../../images/audio/audio-icon11.png"),
          title: "多对多语音",
          description: "多人参与的讨论或会议"
        }
      ]
    };
  },

  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  },

  methods: {
    goAnywhere() {
      window.open("http://customer.paas.hst.com/register?userFrom=PaaS%E5%AE%98%E7%BD%91");
    }
  }
};
</script>

<style scoped>
.banner {
  position: relative;
  margin-bottom: 0.84rem;
}

.banner-btn1 {
  position: absolute;
  cursor: pointer;
  width: 1.44rem;
  height: 0.42rem;
  border-radius: 0.21rem;
  background: #ff9f29;
  color: #ffffff;
  font-size: 0.18rem;
  font-weight: 500;
  line-height: 0.42rem;
  top: 62%;
  left: 19%;
  transition: all 0.3s;
}

.banner-btn1:hover {
  background: #ff8d00;
}

.banner-btn1:active {
  background: #d17300;
}

.banner-btn2 {
  position: absolute;
  cursor: pointer;
  width: 1.44rem;
  height: 0.42rem;
  border: 0.01rem solid #ff9f29;
  border-radius: 0.21rem;
  font-size: 0.18rem;
  font-weight: 500;
  line-height: 0.42rem;
  top: 62%;
  left: 28%;
  transition: all 0.3s;
}

.banner-btn2 a {
  color: #ffffff;
}

.banner-btn2:hover {
  border-color: #ff8d00;
}

.banner-btn2:hover a {
  color: #ff8d00;
}

.banner-btn2:active {
  border-color: #d17300;
}

.banner-btn2:active a {
  color: #d17300;
}

.title {
  font-size: 0.32rem;
  font-weight: 500;
  color: #575a71;
  margin-bottom: 0.45rem;
}

.title::after {
  content: "";
  display: block;
  width: 0.4rem;
  height: 0.04rem;
  background: #495eeb;
  margin: 0.1rem auto;
  margin-top: 0.15rem;
}

.feature {
  margin-bottom: 0.8rem;
}

.feature .feature-container {
  max-width: 10rem;
  margin: 0 auto;
  margin-top: 0.81rem;
}

.feature .feature-card {
  border-radius: 0.24rem;
  border: 0.01rem solid rgba(199, 205, 218, 1);
  padding: 0.32rem 0 0.65rem;
  margin-bottom: 0.2rem;
}

.feature .thumbnail img {
  max-width: 1rem;
  display: inline-block;
  margin-bottom: 0.34rem;
}

.feature .main {
  font-size: 0.24rem;
  font-weight: 500;
  color: #495eeb;
  margin-bottom: 0.26rem;
}

.feature .sub {
  font-size: 0.18rem;
  font-family: "SourceHanSansCN-Regular";
  font-weight: 400;
  color: #929292;
}

.advantage {
  background: #f5f8ff;
  margin-bottom: 0.8rem;
  padding: 0.8rem 0 0.82rem;
}

.advantage .advantage-container {
  max-width: 10.5rem;
  margin: 0 auto;
  margin-top: 0.93rem;
}

.advantage .advantage-card {
  position: relative;
}

.advantage .advantage-card::after {
  position: absolute;
  display: block;
  content: " ";
  background-image: url("../../images/audio/audio-icon08.png");
  background-size: contain;
  width: 0.34rem;
  height: 0.34rem;
  right: -0.39rem;
  top: 0.38rem;
}

.advantage .advantage-card:last-child::after {
  display: none;
}

.advantage .thumbnail img {
  max-width: 1rem;
  display: inline-block;
  margin-bottom: 0.5rem;
}

.advantage .main {
  font-size: 0.24rem;
  font-weight: 500;
  color: #3b51a2;
  margin-bottom: 0.16rem;
}

.advantage .sub {
  font-size: 0.18rem;
  font-family: "SourceHanSansCN-Normal";
  font-weight: 400;
  color: #989cbd;
}

.scene {
  margin-bottom: 0.8rem;
}

.scene .scene-container {
  max-width: 9rem;
  margin: 0 auto;
  margin-top: 0.8rem;
}

.scene .thumbnail img {
  max-width: 1.6rem;
  display: inline-block;
  margin-bottom: 0.49rem;
}

.scene .main {
  font-size: 0.24rem;
  font-weight: 500;
  color: #3b51a2;
  margin-bottom: 0.11rem;
}

.scene .sub {
  font-size: 0.18rem;
  font-family: "SourceHanSansCN-Normal";
  font-weight: 400;
  color: #989cbd;
}

@media screen and (max-width: 576px) {
  .advantage .advantage-card {
    margin-bottom: 0.5rem;
  }

  .banner {
    position: relative;
  }

  .banner-link {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}
</style>