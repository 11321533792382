import Vue from 'vue'
import App from './App.vue'

import Router from 'vue-router'
import iView from 'iview'
import 'normalize.css/normalize.css'
import 'iview/dist/styles/iview.css'
import './css/border.css'
import './css/reset.css'
import './css/global.css'

import routerConfig from './router/router'
import store from './store'

Vue.use(Router)
Vue.use(iView)

Vue.config.productionTip = false

const router = new Router({
  mode: 'history',
  routes: routerConfig,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})

router.beforeEach((to, from, next) => {
  let pathArr = to.path.split('/')

  if (pathArr.includes('developer')) {
    store.commit('changeModel', true)
  } else {
    store.commit('changeModel', false)
  }
  next()
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')